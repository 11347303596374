import { Container, FormControl, Grid, InputLabel, Button, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { getDatabase, set, ref, child, push, update } from 'firebase/database'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export const Requerimientos = () => {

  let navigate = useNavigate();

  const initialValues = {'Nombre':"",'Ciudad':"",'Direccion':"",
    'Celular':"",'Telefono':"", 'Detalle':""};

  const [datos, setDatos] = useState(initialValues);

  const handleChange = (event) => {
    setDatos({...datos, [event.target.name]: event.target.value});
    
    console.log(datos);
  }

  const handleEnviarRequerimiento = () => {

    if(datos.Nombre.length === 0)
    {
      Swal.fire({"title":"Atención","text":"Debe ingresar el Nombre","type":"warning"});
      return;
    }

    if(datos.Ciudad.length === 0)
    {
      Swal.fire({"title":"Atención","text":"Debe ingresar la Ciudad","type":"warning"});
      return;
    }

    if(datos.Direccion.length === 0)
    {
      Swal.fire({"title":"Atención","text":"Debe ingresar la Dirección","type":"warning"});
      return;
    }

    if(datos.Celular.length === 0)
    {
      Swal.fire({"title":"Atención","text":"Debe ingresar el Celular","type":"warning"});
      return;
    }

    if(datos.Telefono.length === 0)
    {
      Swal.fire({"title":"Atención","text":"Debe ingresar el Télefono","type":"warning"});
      return;
    }

    if(datos.Celular.length === 0)
    {
      Swal.fire({"title":"Atención","text":"Debe ingresar el Celular","type":"warning"});
      return;
    }

    

    //Guardar en la base de datos
    const db = getDatabase();
    const newKeyRequerimiento = push(child(ref(db),`/TestRequerimientos`)).key;

    const newRequerimiento = {
      Nombre: datos.Nombre,
      Ciudad: datos.Ciudad,
      Direccion: datos.Direccion,
      Celular: datos.Celular,
      Telefono: datos.Telefono,
      Detalle: datos.Detalle,
      Fecha: new Date().toISOString(),
      RequerimientoId: newKeyRequerimiento
    }

    update(child(ref(db),`/Requerimientos/${newKeyRequerimiento}`), newRequerimiento);

    setDatos(initialValues);
    navigate('/panel');

    //console.log("datos",datos);
    Swal.fire("Enviado", "Su requerimiento ha sido enviado, pronto sera contactado por un experto en carpinteria", "success");
  }

  return (
    <div>
        <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link 
              to="/panel"
              className="btn-return"
          >
             Volver  
        </Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top" />
        
        <h1 className="title">Necesito un carpintero</h1>
        <p className="subtitle">Solicita un servicio de carpinteria con personal experto y capacitado</p>
        <hr className="space"/>

        {/* <div style={{border:"solid 1px red",borderRadius:"20px", padding:"50px"}}>
            <img src={logo} alt="Logo" height={120} width={120}></img>
            <br />
            Estamos cosntruyendo una maravillosa experiencia para ti, esperala muy pronto!!!
        </div> */}

        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Nombre Completo" variant='outlined' fullWidth name="Nombre" onChange={handleChange} >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Ciudad" variant='outlined' fullWidth name="Ciudad" onChange={handleChange}  >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Dirección" variant='outlined' fullWidth name="Direccion" onChange={handleChange} >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Celular" variant='outlined' fullWidth name="Celular" onChange={handleChange} >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Télefono" variant='outlined' fullWidth name="Telefono" onChange={handleChange} >

            </TextField>
          </Grid>
          
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Detalle" multiline maxRows={4} variant='outlined' fullWidth name="Detalle" onChange={handleChange} >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <Button variant="contained" color="primary" onClick={handleEnviarRequerimiento}>Enviar Requerimiento</Button>
          </Grid>
          
        </Grid>
        

        </Container>
    </div>
  )
}
