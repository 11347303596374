import { Container, Select } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';

export const PagarForma = () => {

    const handleContinuar = () => {
        //dispatch(setAdicionarProducto(producto));
        alert('Continuar');
      }

  return (
    
      <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link to={`/carrito`}>Volver</Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <p>Bienvenido a la plataforma de agos electrónicos de hágalo en casa</p>
        <p>Por favor seleccione la forma de pago</p>
        <Select>
            <option value="1">Pago PSE</option>
            <option value="2">Pago Tarjeta de Crédito</option>
        </Select>
        <Link to={`/pagarPSE`}>Pago PSE</Link>
        <Link to={`/pagarTC`}>Pago Tarjeta de Crédito</Link>
        <br />
        <br />
        <button
          onClick={handleContinuar}
          className="btn btn-primary"
          >
            Continuar
          </button>
          <br />
          <br />
        <button
          onClick={handleContinuar}
          className="btn btn-primary"
          >
            Cancelar
          </button>
      </ Container>
  )
}
