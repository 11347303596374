import React from 'react'
import { Container, FormControl, Grid, InputLabel, Button, MenuItem, Select, TextField } from '@mui/material'
import { Link } from 'react-router-dom'

export const NotFound = () => {
  return (
    <Container fixed>
      <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link 
              to="/panel"
              className="btn-return"
          >
             Volver  
        </Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top" />
      <div>No hemos encontrado la página que buscas</div>
    </Container>
    

  )
}
