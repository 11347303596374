import React from 'react'
import { Navigate } from 'react-router-dom'

export const PublicRoutes = ({children, isLoggedIn}) => {

    console.log("children" + children + "loggued in: " + isLoggedIn);
    
    return isLoggedIn
        ? <Navigate to="/panel" />
        : children


    
}