import React, {useState, useEffect} from 'react'
import { child, getDatabase, onValue, ref } from "firebase/database";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button} from '@mui/material';



export const BuscarProductos = ({closeSearch, getProduct}) => {


    const initialValuesProductos = [{'itemId':'','descripcion':'','valorUnitario':'', 'unidad':''}];
    const [datosProductos, setDatosProductos] = useState(initialValuesProductos);

    //return data to parent Component
    

    useEffect(() => {

    
        const db = getDatabase();
        const productosRef = child(ref(db),`/Items`);
    
        const productos = [];
        
        onValue(productosRef, (snapshot) => {
           
            snapshot.forEach(note => {            
                productos.push(note.val());            
            });
    
            setDatosProductos(productos);
            console.log(productos);
            
        })
      }, [setDatosProductos]);

     
  return (
    <div style={{height:'450px'}}>
       
            <DataGrid 
                
                
                components={{ Toolbar: GridToolbar }} 
                getRowId={(r) => r.itemId}
                columns={
                    [
                        {field: 'descripcion', title: 'Descripcion', minWidth:400},
                        {field: 'valorUnitario', title: 'Valor Unitario', minWidth:200},
                        {field: 'unidad', title: 'UDM', minWidth:100},
                        {
                            field: 'Seleccionar',
                            headerName: 'Seleccionar',
                            renderCell: (params) => {
                                return (
                                    <div>
                                        <Button onClick={() => {
                                            //console.log(params.row);
                                            //alert(params.row.descripcion);
                                            closeSearch();
                                            getProduct(params.row);
                                        } }>Seleccionar</Button>
                                    </div>
                                );
                            }
                        }
                    ]
                }
                rows={datosProductos}>

            </DataGrid>
        
        
    </div>
  )
}
