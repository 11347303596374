import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React from 'react'

export const PagarPSE = () => {
  return (
    <div>
      <Container>
        <Box sx={{ paddingTop: '20px' }} />
        <Typography variant="h5" component="h6">
          Pago por medio de PSE
        </Typography>
        <Box sx={{ paddingTop: '20px' }} />
        {/* <p>
          Por favor diligencia ka siguiente información para proceder con el pago por medio de PSE
        </p> */}

        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Banco</InputLabel>
              <Select label="banco" fullWidth>
              <MenuItem value="">
                <em>Seleccione un banco</em>
              </MenuItem>
            </Select>
            </FormControl>
            
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Valor" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tipo Documento</InputLabel>
              <Select label="banco" fullWidth>
              <MenuItem value="">
                <em>Seleccione tipo documento</em>
              </MenuItem>
              <MenuItem value={'CC'}>Cédula de Ciudadania</MenuItem>
              <MenuItem value={'NI'}>Nit</MenuItem>
            </Select>
            </FormControl>
            
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Número Documento" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Nombres" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Apellidos" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Correo Electrónico" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Celular" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Télefono" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
            <Button variant='contained' fullWidth>Pagar</Button>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
            <Button variant='contained' fullWidth>Cancelar</Button>
          </Grid>
        </Grid>
        
      </Container>
      

    </div>
  )
}
