import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import { child, getDatabase, onValue, ref } from "firebase/database";
import {Buffer} from 'buffer';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button} from '@mui/material';


export const BuscarClientes = ({closeSearch, getCustomer}) => {
    
    const {email} = useSelector( state => state.auth );

    const initialValuesCientes = [{'ClienteId':'','Nit':'','Nombre':''}];
    const [datosClientes, setDatosClientes] = useState(initialValuesCientes);


    useEffect(() => {



        let buff = new Buffer(email);
        let stringToBase64 = buff.toString('base64');
    
        const db = getDatabase();
        const clientesRef = child(ref(db),`/Clientes/${stringToBase64}`);
    
        const clientes = [];
        
        onValue(clientesRef, (snapshot) => {
           
            snapshot.forEach(note => {            
                clientes.push(note.val());            
            });
    
            setDatosClientes(clientes);
            console.log(clientes);
            
        })
      }, [setDatosClientes, email]);

  return (
    <div style={{height:'450px'}}>
        <DataGrid 
                
                
                components={{ Toolbar: GridToolbar }} 
                getRowId={(r) => r.ClienteId}
                columns={
                    [
                        {field: 'Nit', title: 'Nit', minWidth:200},
                        {field: 'Nombre', title: 'Nombre', minWidth:500},
                        {
                            field: 'Seleccionar',
                            headerName: 'Seleccionar',
                            renderCell: (params) => {
                                return (
                                    <div>
                                        <Button onClick={() => {
                                            //console.log(params.row);
                                            //alert(params.row.descripcion);
                                            closeSearch();
                                            getCustomer(params.row);
                                        } }>Seleccionar</Button>
                                    </div>
                                );
                            }
                        }
                    ]
                }
                rows={datosClientes}>

            </DataGrid>
    </div>
  )
}
