import React from 'react'
import { Navigate } from 'react-router-dom'



export const PrivateRoutes = ({children, isLoggedIn}) => {

     console.log("children" + children + "loggued in: " + isLoggedIn);   

    return isLoggedIn
        ? children
        : <Navigate to="/auth/login" />


}
