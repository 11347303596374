import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { startAppleLogin, startGoogleLogin, startLoginEmailPassword } from '../../actions/auth';
import { useForm } from '../../hooks/useForm';
import logo from '../../martillo_logo.jpg';


export const LoginScreen = () => {

  const dispatch = useDispatch();
    const {loading} = useSelector( state => state.ui );
    

    const [formValues, handleInputChange] = useForm({
        // email: 'yovanytabarestest@gmail.com',
        // password: '123456'
        email: '',
        password: ''
    })

    const  {email, password} = formValues;

    const handleLogin = (e) => {
        e.preventDefault();
        //console.log(email, password);
        dispatch(startLoginEmailPassword(email,password));
    }

    const handleGoogleLogin = () => {
        dispatch(startGoogleLogin());
    }

    const handleAppleLogin = () => {
        dispatch(startAppleLogin());
    }

    return (
        <div className='auth__main'>
        <div className='auth__box-container'>
          <img src={logo} alt='logo' className='auth__logo'/>
            <h3 className="auth__title">Acceso Hágalo en Casa</h3>

            <form className='animate__animated animate__fadeIn animate__faster' onSubmit={handleLogin}>

                <input 
                    type="text"
                    placeholder="Correo electrónico"
                    name="email"
                    className="auth__input"
                    autoComplete="off"
                    value={email}
                    onChange={handleInputChange}
                />

                <input 
                    type="password"
                    placeholder="Contraseña"
                    name="password"
                    className="auth__input"
                    value={password}
                    onChange={handleInputChange}
                />


                <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={loading}
                >
                    Acceder
                </button>

                
                <div className="auth__social-networks">
                    <p>ó</p>

                    <div 
                        className="google-btn"
                        onClick={handleGoogleLogin}
                    >
                        <div className="google-icon-wrapper">
                            <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt="google button" />
                        </div>
                        <p className="btn-text">
                            <b>Continuar con google</b>
                        </p>
                    </div>
                </div>

                {/* <div className="auth__social-networks" style={{marginTop:"-40px"}}>
                    <div 
                        className="apple-btn"
                        onClick={handleAppleLogin}
                    >
                        <div className="apple-icon-wrapper">
                            <img className="apple-icon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/488px-Apple_logo_black.svg.png" alt="google button" />
                        </div>
                        <p className="btn-text">
                            <b>Continuar con Appple</b>
                        </p>
                    </div>
                </div> */}

                <Link 
                    to="/auth/register"
                    className="link"
                >
                    Registrarme    
                </Link>

            </form>
        </div>
        </div>
    )
}
  