export const getResponseTransaction = async(ref_payco) => {
    const url = `https://secure.epayco.co/validation/v1/reference/${ref_payco}`;
    const resp = await fetch( url );
    const { data }  = await resp.json();

    // const response = data.map( d => {
    //         return {
    //             respuesta: d.data.x_respuesta
    //         }
    // });
 return data
}