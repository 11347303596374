import { Container } from '@mui/material'
import { child, getDatabase, onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { PlanoCard } from './PlanoCard';

export const Planos = () => {

  const {email} = useSelector( state => state.auth );

  const initialValues = [{"Codigo":"","Descripcion":"","Gratuito":"","UrlDocumento":""}];

  const [notas, setNotas] = useState(initialValues);

  useEffect(() => {
    const db = getDatabase();
    const planosRef = child(ref(db),`Planos`);
    const permisosPlanosRef = child(ref(db),`Permisos/Planos`);
    const allPlanos = [];
    const allPlanosVideos = [];
    let userAllowPlanos = false;

    onValue(permisosPlanosRef, (snapshot) => {      

      snapshot.forEach(permiso => {            
        allPlanosVideos.push(permiso.val());            
      });

        userAllowPlanos = allPlanosVideos.find(permiso => permiso.Email === email);
        
        if(userAllowPlanos){       
          console.log('Usuario autorizado');
        }
        else{
          console.log('Usuario no autorizado');
        }
        
    })
    
    onValue(planosRef, (snapshot) => {      

        snapshot.forEach(video => {            
          allPlanos.push(video.val());            
        });

        if(userAllowPlanos){
          setNotas(allPlanos)
        }
        else{
          const freeVideos = allPlanos.filter(note => note.Gratuito === true);
          setNotas(freeVideos)
        }

        //console.log(notas);
        
    })
  }, [setNotas, email]);


  return (
    <div>
        <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link 
              to="/panel"
              className="btn-return"
          >
             Volver  
        </Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top" />
        
        <h1 className="title">Planos</h1>
        <p className="subtitle">Accede a planos de carpinteria</p>
        <hr className="space"/>

        {
          notas.map( note => (
            <PlanoCard 
            key={note.Codigo}
            {...note} />
          ))
        
        }

        {/* <div style={{border:"solid 1px red",borderRadius:"20px", padding:"50px"}}>
            <img src={logo} alt="Logo" height={120} width={120}></img>
            <br />
            Estamos cosntruyendo una maravillosa experiencia para ti, esperala muy pronto!!!
        </div> */}

        </Container>
    </div>
  )
}
