import { Grid } from '@mui/material'
import React from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { setEliminarProducto, setReducirValorPedido } from '../../actions/productos'

export const CarritoItem = ({CategoriaCodigo, Codigo, Nombre, Notas, PorcentajeDescuento, Cantidad, Precio, ValorTotal, UrlImagen}) => {
  
  const dispatch = useDispatch()

  const handleEliminar = () => {
    dispatch(setEliminarProducto(Codigo));
    dispatch(setReducirValorPedido(ValorTotal));

    var pedido = localStorage.getItem('pedido');
    pedido = JSON.parse(pedido);
    pedido = pedido.filter(producto => producto.Codigo !== Codigo);
    localStorage.setItem('pedido', JSON.stringify(pedido));

    
    Swal.fire("Atención", `Eliminando producto ${Nombre} `, "warning");
  }
  
  return (
    <div className="card">
    <Grid container spacing={0}>
      <Grid item xs={3} md={2} xl={1}>
        <img src={UrlImagen} alt="Logo" height={60} width={60}></img>
      </Grid>
      <Grid item xs={9} md={10} xl={11}>
        <h3 className="title">{Nombre}</h3>
        <p>
          {Cantidad} 
          x 
          <NumberFormat value={Precio} displayType={'text'} thousandSeparator={true} prefix={' $ '} />
          = 
          <strong>
           Total: 
            <NumberFormat value={ValorTotal} displayType={'text'} thousandSeparator={true} prefix={' $ '} />
        </strong>

        </p>
        
        <br />
        <button 
          onClick={handleEliminar}
          className='btn btn-primary'
          > Eliminar </button>
      </Grid>
      
    </Grid>
    
    
  </div>
  )
}
