import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import {Container, Grid, TextField}  from '@mui/material'
import { child, update, push, getDatabase, onValue, ref } from "firebase/database";
import {Buffer} from 'buffer';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

export const DatosClientes = () => {

  const {email} = useSelector( state => state.auth );
  const db = getDatabase();
  let buff = new Buffer(email);
  let stringToBase64 = buff.toString('base64');

  const initialValuesCliente = {'Nombre': '', 'Nit': '', 'Direccion': '', 'Telefono': '', 'Celular': '', 'Correo': '', 'Ciudad': '', 'NotasCliente':'', 'ClienteId':''};
  const [datosCliente, setDatosCliente] = useState(initialValuesCliente);

  useEffect(() => {
    //validar debido a que en la primera entrada no se asigna el id
    setDatosCliente({...datosCliente, 'ClienteId': uuidv4()});
  }, [datosCliente]);

  const handleChangeEnvio = (event) => {
    setDatosCliente({...datosCliente, [event.target.name]: event.target.value});

    
    //console.log([event.target.name]);
    //console.log(event.target.value);
    //console.log(datosEnvio);
  }

  const handleGuardar = (event) => {
    event.preventDefault();

    if(datosCliente.Nombre.length === 0){
      Swal.fire("Advertencia", "Debe ingresar el nombre del cliente", "warning");
      return;
    }

    if(datosCliente.Nit.length === 0){
      Swal.fire("Advertencia", "Debe ingresar el numero de identificación", "warning");
      return;
    }

    if(datosCliente.Direccion.length === 0){
      Swal.fire("Advertencia", "Debe ingresar la dirección", "warning");
      return;
    }

    if(datosCliente.Telefono.length === 0){
      Swal.fire("Advertencia", "Debe ingresar el teléfono", "warning");
      return;
    }

    if(datosCliente.Celular.length === 0){
      Swal.fire("Advertencia", "Debe ingresar el celular", "warning");
      return;
    }

    if(datosCliente.Correo.length === 0){
      Swal.fire("Advertencia", "Debe ingresar el correo electrónico", "warning");
      return;
    }

    if(datosCliente.Ciudad.length === 0){
      Swal.fire("Advertencia", "Debe ingresar la ciudad", "warning");
      return;
    }

    if(datosCliente.NotasCliente.length === 0){
      setDatosCliente({...datosCliente, 'NotasCliente': 'Sin notas'}); 
      
    }

    

    const newKeyCliente = push(child(ref(db),`/Clientes/${stringToBase64}`)).key;

    update(child(ref(db),`/Clientes/${stringToBase64}/${newKeyCliente}`), datosCliente);


    Swal.fire("Guardado", "Los datos de tu cliente se han guardado con exito", "success");
  }


  return (
    <div>
      
      <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link to={`/cotizaciones`} className="btn-return">Volver a Cotizaciones</Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top"  />
        <h1>Datos de Cliente</h1>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Nombre" variant='outlined' fullWidth name="Nombre" onChange={handleChangeEnvio} value={datosCliente.Nombre}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Nit/CC" variant='outlined' fullWidth name="Nit" onChange={handleChangeEnvio} value={datosCliente.Nit}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Dirección" variant='outlined' fullWidth name="Direccion" onChange={handleChangeEnvio} value={datosCliente.Direccion}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Teléfono" variant='outlined' fullWidth name="Telefono" onChange={handleChangeEnvio} value={datosCliente.Telefono}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Celular" variant='outlined' fullWidth name="Celular" onChange={handleChangeEnvio} value={datosCliente.Celular}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Correo" variant='outlined' fullWidth name="Correo" onChange={handleChangeEnvio} value={datosCliente.Correo}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Ciudad" variant='outlined' fullWidth name="Ciudad" onChange={handleChangeEnvio} value={datosCliente.Ciudad}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="NotasCliente" variant='outlined' fullWidth name="NotasCliente" onChange={handleChangeEnvio} value={datosCliente.NotasCliente}  >

              </TextField>
            </Grid>
            
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <button className="btn btn-primary" onClick={handleGuardar}>
                Guardar Datos Cliente
              </button>
            </Grid>
          
        </Grid>
      </Container>


    </div>
  )
}
