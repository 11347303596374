import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { child, update, getDatabase, onValue, ref, push, set} from "firebase/database";
import {Container, Grid, TextField, Checkbox, Box, Select, MenuItem, FormControl, InputLabel}  from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {Buffer} from 'buffer';
import Swal from 'sweetalert2';


export const ConfigurarCotizacion = () => {

  const {email} = useSelector( state => state.auth );
  let buff = new Buffer(email);
  //db
  const db = getDatabase();
  let stringToBase64 = buff.toString('base64');
  const [key, setKey] = useState('');

 
  const initialValuesObservaciones = {'Selected':false, 'Observaciones': ''};
  const initialValuesOpcionesAnticipo = [{'Codigo':'', 'Descripcion':''}];
  const initialValuesOpcionesEntrega = [{'Codigo':'', 'Descripcion':''}];
  const initialValuesOpcionesVigencia = [{'Codigo':'', 'Descripcion':''}];
  const initialValuesOpcionesDescuentoEfectivo = [{'Codigo':'', 'Descripcion':''}];
  const initialValuesOpcionesOtrosDescuentos = [{'Codigo':'', 'Descripcion':''}];
  const initialValuesCondicionesComerciales = [{'Entrega': '', 'PorcentajeAnticipo':'', 'PorcentajeDescuentoEfectivo':'', 'PorcentajeOtrosDescuentos':'','Vigencia':''}]
  const [datosObservaciones, setDatosObservaciones] = useState(initialValuesObservaciones);
  const [datosOpcionesAnticipo, setDatosOpcionesAnticipo] = useState(initialValuesOpcionesAnticipo);
  const [datosOpcionesEntrega, setDatosOpcionesEntrega] = useState(initialValuesOpcionesEntrega);
  const [datosOpcionesVigencia, setDatosOpcionesVigencia] = useState(initialValuesOpcionesVigencia);
  const [datosOPcionesDescuentoEfectivo, setDatosOPcionesDescuentoEfectivo] = useState(initialValuesOpcionesDescuentoEfectivo);
  const [datosOpcionesOtrosDescuentos, setDatosOpcionesOtrosDescuentos] = useState(initialValuesOpcionesOtrosDescuentos);
  const [condicionesComerciales, setCondicionesComerciales] = useState(initialValuesCondicionesComerciales);
  const [anticipo, setAnticipo] = useState('');
  const [entrega, setEntrega] = useState('');
  const [vigencia, setVigencia] = useState('');
  const [descuentoEfectivo, setDescuentoEfectivo] = useState('');
  const [otrosDescuentos, setOtrosDescuentos] = useState('');  
  
  const handleChangeSelected = (event) => {

    console.log(event.target.name);

    setDatosObservaciones(prevState => {
      const newState = prevState.map(item => {
          if(item.Codigo === event.target.name){
            //alert(item.Descripcion + " " +  event.target.checked);
            return {...item, 'Selected': event.target.checked};            
          }

          return item;
        })

        return newState;
      });

      
      //console.log(datosObservaciones);
    
  }

  const handleGuardar = (event) => {
    event.preventDefault();
    console.log(anticipo);
    if(anticipo === '')
    {
      Swal.fire("Advertencia", "Debe selecionar un anticipo", "warning");
      return;
    }

    if(entrega === '')
    {
      Swal.fire("Advertencia", "Debe selecionar una entrega", "warning");
      return;
    }

    if(vigencia === '')
    {
      Swal.fire("Advertencia", "Debe selecionar una vigencia", "warning");
      return;
    }

    if(descuentoEfectivo === '')
    {
      Swal.fire("Advertencia", "Debe selecionar un descuento efectivo", "warning");
      return;
    }

    if(otrosDescuentos === '')
    {
      Swal.fire("Advertencia", "Debe selecionar un otros descuentos", "warning");
      return;
    }

    var textEntrega = "";
    var textAnticipo = "";
    var textDescuentoEfectivo = "";
    var textOtrosDescuentos = "";
    var textVigencia = "";

    switch(entrega)
    {
      case '00':
        textEntrega = 'N/A';
        break;
      case '01':
        textEntrega = '8 días hábiles';
        break;
      case '02':
        textEntrega = '15 días hábiles';
        break;
      case '03':
        textEntrega = '20 días hábiles';
        break;
      case '04':
        textEntrega = '25 días hábiles';
        break;
      case '05':
        textEntrega = '30 días hábiles';
        break;
      case '06':
        textEntrega = '45 días hábiles';
        break;
      case '07':
        textEntrega = '60 días habiles';
        break;
      default:
        textEntrega = 'N/A';
        break;
    }

    switch(anticipo)
    {
      case '01':
        textAnticipo = '0%';
        break;
      case '02':
        textAnticipo = '10%';
        break;
      case '03':
        textAnticipo = '20%';
        break;
      case '04':
        textAnticipo = '30%';
        break;
      case '05':
        textAnticipo = '40%';
        break;
      case '06':
        textAnticipo = '50%';
        break;
      case '07':
        textAnticipo = '60%';
        break;
      case '08':
        textAnticipo = '70%';
        break;
      case '09':
        textAnticipo = '80%';
        break;
      case '10':
        textAnticipo = '90%';
        break;
      default:
        textAnticipo = 'N/A';
          break;
    }

    switch(descuentoEfectivo)
    {
      case '01':
        textDescuentoEfectivo = '0%';
        break;
      case '02':
        textDescuentoEfectivo = '3%';
        break;
      case '03':
        textDescuentoEfectivo = '5%';
        break;
      default:
        textDescuentoEfectivo = '0%';
        break;
    }

    switch(otrosDescuentos)
    {
      case '00':
        textOtrosDescuentos = '0%';
        break;
      case '01':
        textOtrosDescuentos = '1%';
        break;
      case '02':
        textOtrosDescuentos = '2%';
        break;
      case '03':
        textOtrosDescuentos = '3%';
        break;
      case '04':
        textOtrosDescuentos = '4%';
        break;
      case '05':
        textOtrosDescuentos = '5%';
        break;
      case '06':
        textOtrosDescuentos = '6%';
        break;
      case '07':
        textOtrosDescuentos = '7%';
        break;
      case '08':
        textOtrosDescuentos = '8%';
        break;
      case '09':
        textOtrosDescuentos = '9%';
        break;
      case '10':
        textOtrosDescuentos = '10%';
        break;
      case '11':
        textOtrosDescuentos = '11%';
        break;
      case '12':
        textOtrosDescuentos = '12%';
        break;
      case '13':
        textOtrosDescuentos = '13%';
        break;
      case '14':
        textOtrosDescuentos = '14%';
        break;
      case '15':
        textOtrosDescuentos = '15%';
        break;
      default:
        textOtrosDescuentos = '0%';
        break;
    }

    switch(vigencia)
    {
      case '00':
        textVigencia = 'N/A';
        break;
      case '01':
        textVigencia = '15 días';
        break;
      case '02':
        textVigencia = '30 días';
        break;
      default:
        textVigencia = 'NA';
        break;
    }

    const condicionesComerciales = {      
      'Entrega': textEntrega,
      'PorcentajeAnticipo': textAnticipo,
      'PorcentajeDescuentoEfectivo': textDescuentoEfectivo,
      'PorcentajeOtrosDescuentos': textOtrosDescuentos,
      'Vigencia': textVigencia
    }

    update(child(ref(db),`/CotizacionCondicionesComerciales/${stringToBase64}/${key}`), condicionesComerciales);

    //llenar nuevas observaciones forEach ok
    datosObservaciones.forEach(observacion => {

      if(observacion.Selected)
      {
        const newKeyObservacion = push(child(ref(db),`/CotizacionObservaciones/${stringToBase64}`)).key;
        
        var newObservacion = {
          CotizacionObservacionId : observacion.Codigo,
          Descripcion: observacion.Descripcion
        }

        //validate if the observacion is already in the database
        var observacionRef = child(ref(db),`/CotizacionObservaciones/${stringToBase64}/${observacion.Key}`);
        onValue(observacionRef, (snapshot) => {
          if(snapshot.val() == null)
          {
            //console.log("No existe la observacion", observacion);
            set(ref(db,`/CotizacionObservaciones/${stringToBase64}/${newKeyObservacion}`),newObservacion);
          }
          else{
            //console.log("Ya existe la observacion", observacion);
          }
        });
        
      }
      else{
        //console.log("Eliminar Observación", observacion);
        set(ref(db,`/CotizacionObservaciones/${stringToBase64}/${observacion.Key}`),null);
      }
    });     

    Swal.fire("Guardado", "Los datos de configuración se han guardado con exito", "success");
  }


  useEffect(() => {
      
      //get Cotizacion Observaciones
      const observacionesCotizacionRef = child(ref(db),`/CotizacionObservaciones/${stringToBase64}`);
      const observacionesCotizacion = [];
      onValue(observacionesCotizacionRef, (snapshot) => {
        
        snapshot.forEach(function(childSnapshot) {
          
          observacionesCotizacion.push({...childSnapshot.val(), 'key':childSnapshot.key});
        });

        //console.log("Observaciones Cotización", observacionesCotizacion);
            
      });

      //get Cotizacion Condiciones Comerciales
      const condicionesComercialesRef = child(ref(db),`/CotizacionCondicionesComerciales/${stringToBase64}`);
      
      onValue(condicionesComercialesRef, (snapshot) => {
        for(let key in snapshot.val()){
          setKey(key);
        }

        snapshot.forEach(function(childSnapshot) {
          //console.log("Condiciones Comerciales", childSnapshot.val());
          setCondicionesComerciales(childSnapshot.val());
        });
      } );
      


      //get Observaciones
      const observacionesRef = child(ref(db),`/ObservacionesCotizacion`);    
      const observaciones = [];        
      onValue(observacionesRef, (snapshot) => {           
          snapshot.forEach(function(childSnapshot)  {  
             var key = observacionesCotizacion.find(x => x.CotizacionObservacionId === childSnapshot.val().Codigo);
              if(observacionesCotizacion.find(x => x.CotizacionObservacionId === childSnapshot.val().Codigo)){                              
                observaciones.push({...childSnapshot.val(), 'Selected':true, "Key": key.key});    
              }
              else{
                observaciones.push({...childSnapshot.val(), 'Selected':false});
              }        
          });    
          setDatosObservaciones(observaciones);
          //console.log("Observaciones", observaciones); 
           
      })

      //get Opciones Anticipo
      const opcionesAnticipoRef = child(ref(db),`/OpcionesAnticipo`);
      const opcionesAnticipo = [];
      onValue(opcionesAnticipoRef, (snapshot) => {
          snapshot.forEach(note => {            
              opcionesAnticipo.push(note.val());            
          });    
          setDatosOpcionesAnticipo(opcionesAnticipo);
          //console.log("Anticipo", opcionesAnticipo);            
      })

     
      

      //get Opciones Entrega
      const opcionesEntregaRef = child(ref(db),`/OpcionesEntrega`);
      const opcionesEntrega = [];
      onValue(opcionesEntregaRef, (snapshot) => {
          snapshot.forEach(note => {            
              opcionesEntrega.push(note.val());            
          });    
          setDatosOpcionesEntrega(opcionesEntrega);
          //console.log(opcionesEntrega);            
      })

      

      //get Opciones Vigencia
      const opcionesVigenciaRef = child(ref(db),`/OpcionesVigencia`);
      const opcionesVigencia = [];
      onValue(opcionesVigenciaRef, (snapshot) => {
          snapshot.forEach(note => {            
              opcionesVigencia.push(note.val());            
          });    
          setDatosOpcionesVigencia(opcionesVigencia);
          //console.log(opcionesVigencia);            
      })

      //get Opciones Descuento Efectivo
      const opcionesDescuentoEfectivoRef = child(ref(db),`/OpcionesDescuentoEfectivo`);
      const opcionesDescuentoEfectivo = [];
      onValue(opcionesDescuentoEfectivoRef, (snapshot) => {
          snapshot.forEach(note => {            
              opcionesDescuentoEfectivo.push(note.val());            
          });    
          setDatosOPcionesDescuentoEfectivo(opcionesDescuentoEfectivo);
          //console.log(opcionesDescuentoEfectivo);            
      })

      //get Opciones Otros Descuentos
      const opcionesOtrosDescuentosRef = child(ref(db),`/OpcionesOtrosDescuentos`);
      const opcionesOtrosDescuentos = [];
      onValue(opcionesOtrosDescuentosRef, (snapshot) => {
          snapshot.forEach(note => {            
              opcionesOtrosDescuentos.push(note.val());            
          });    
          setDatosOpcionesOtrosDescuentos(opcionesOtrosDescuentos);
          //console.log(opcionesOtrosDescuentos);            
      })

       //select anticipo
       const selectAnticipo = opcionesAnticipo.find(x => x.Descripcion === condicionesComerciales.PorcentajeAnticipo);
       if(selectAnticipo)
       {
         setAnticipo(selectAnticipo.Codigo);
         //console.log("Anticipo",selectAnticipo);
       }
       
      //select entrega
      const selectEntrega = opcionesEntrega.find(x => x.Descripcion === condicionesComerciales.Entrega);
      if(selectEntrega)
      {
        setEntrega(selectEntrega.Codigo);
        //console.log("Entrega",selectEntrega);
      }

      //select vigencia
      const selectVigencia = opcionesVigencia.find(x => x.Descripcion === condicionesComerciales.Vigencia);
      if(selectVigencia)
      {
        setVigencia(selectVigencia.Codigo);
        //console.log("Vigencia",selectVigencia);
      }

      //select descuento efectivo
      const selectDescuentoEfectivo = opcionesDescuentoEfectivo.find(x => x.Descripcion === condicionesComerciales.PorcentajeDescuentoEfectivo);
      if(selectDescuentoEfectivo)
      {
        setDescuentoEfectivo(selectDescuentoEfectivo.Codigo);
        //console.log("Descuento Efectivo",selectDescuentoEfectivo);
      }
      else{
        //console.log("Descuento Efectivo",selectDescuentoEfectivo);
      }

      //select otros descuentos
      const selectOtrosDescuentos = opcionesOtrosDescuentos.find(x => x.Descripcion === condicionesComerciales.PorcentajeOtrosDescuentos);
      if(selectOtrosDescuentos)
      {
        setOtrosDescuentos(selectOtrosDescuentos.Codigo);
        //console.log("Otros Descuentos",selectOtrosDescuentos);
      }
      else{
        //console.log("Otros Descuentos",selectOtrosDescuentos);
      }
          


  } , [setDatosObservaciones, setDatosOpcionesAnticipo, setDatosOpcionesEntrega, setDatosOpcionesVigencia, setDatosOPcionesDescuentoEfectivo, setDatosOpcionesOtrosDescuentos, stringToBase64, setCondicionesComerciales, setAnticipo, setEntrega, setVigencia, setDescuentoEfectivo, setOtrosDescuentos, condicionesComerciales.PorcentajeAnticipo, condicionesComerciales.Entrega, condicionesComerciales.Vigencia, condicionesComerciales.PorcentajeDescuentoEfectivo, condicionesComerciales.PorcentajeOtrosDescuentos, db]);

  return (
    <div>
      
      <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link to={`/cotizaciones`} className="btn-return">Volver a Cotizaciones</Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top"  />
        <h1>Configuración de la Cotización</h1>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Grid container spacing={5}>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h2>Observaciones Cotización</h2>
                <div style={{height:'630px'}}>
                <DataGrid
                components={{ Toolbar: GridToolbar }} 
                getRowId={(r) => r.Codigo}
                columns={
                  [
                      {field: 'Codigo', title: 'Codigo',
                        renderCell: (params) => {
                            return (
                                <div>
                                    <Checkbox name={params.row.Codigo} onChange={handleChangeSelected} checked={params.row.Selected} />
                                </div>
                            )
                        },
                        minWidth:50},
                      {field: 'Descripcion', title: 'Descripcion', minWidth:1000}
                      
                  ]
              }
                  rows={datosObservaciones}>

                </DataGrid>
                </div>
            </Grid>

            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <FormControl fullWidth >
                <InputLabel>Anticipo</InputLabel>
                <Select label="Anticipo" 
                onChange={(e) => setAnticipo(e.target.value)}
                fullWidth 
                value={anticipo}>
                  <MenuItem key="-1" value="">
                    <em>Seleccione Anticipo</em>
                  </MenuItem>
                  {
                    datosOpcionesAnticipo.map((opcion, index) => {
                      return (
                        <MenuItem key={index} value={opcion.Codigo}>{opcion.Descripcion}</MenuItem>
                      )
                    } )
                  }
                </Select>
              </FormControl>
              
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <FormControl fullWidth >
                <InputLabel>Entrega</InputLabel>
                <Select label="Entrega" 
                onChange={(e) => setEntrega(e.target.value)}
                fullWidth 
                value={entrega} >
                  <MenuItem key="-1" value="">
                    <em>Seleccione Entrega</em>
                  </MenuItem>
                  {
                    datosOpcionesEntrega.map((opcion, index) => {
                      return (
                        <MenuItem key={index} value={opcion.Codigo}>{opcion.Descripcion}</MenuItem>
                      )
                    } )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <FormControl fullWidth >
                <InputLabel>Vigencia</InputLabel>
                <Select label="Vigencia" 
                onChange={(e) => setVigencia(e.target.value)}
                fullWidth 
                value={vigencia}>
                  <MenuItem key="-1" value="">
                    <em>Seleccione Vigencia</em>
                  </MenuItem>
                  {
                    datosOpcionesVigencia.map((opcion, index) => {
                      return (
                        <MenuItem key={index} value={opcion.Codigo}>{opcion.Descripcion}</MenuItem>
                      )
                    } )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <FormControl fullWidth >
                <InputLabel>Descuento Efectivo</InputLabel>
                <Select label="Descuento_Efectivo"
                 fullWidth 
                 onChange={(e) => setDescuentoEfectivo(e.target.value)}
                 value={descuentoEfectivo}>
                  <MenuItem key="-1" value="">
                    <em>Seleccione Descuento Efectivo</em>
                  </MenuItem>
                  {
                    datosOPcionesDescuentoEfectivo.map((opcion, index) => {
                      return (
                        <MenuItem key={index} value={opcion.Codigo}>{opcion.Descripcion}</MenuItem>
                      )
                    } )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <FormControl fullWidth >
                <InputLabel>Otros Descuentos</InputLabel>
                <Select label="Otros_Descuentos" 
                onChange={(e) => setOtrosDescuentos(e.target.value)}
                fullWidth 
                value={otrosDescuentos}>
                  <MenuItem key="-1" value="">
                    <em>Seleccione Otros Descuentos</em>
                  </MenuItem>
                  {
                    datosOpcionesOtrosDescuentos.map((opcion, index) => {
                      return (
                        <MenuItem key={index} value={opcion.Codigo}>{opcion.Descripcion}</MenuItem>
                      )
                    } )
                  }
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <button className="btn btn-primary" onClick={handleGuardar}>
                Guardar Configuración
              </button>
            </Grid>
          
        </Grid>
      </Container>


    </div>
  )
}
