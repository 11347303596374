import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { login } from '../actions/auth'
import { LoginScreen } from '../components/auth/LoginScreen'
import { RegisterScreen } from '../components/auth/RegisterScreen'
import { Cotizaciones } from '../components/Cotizaciones/Cotizaciones'
import { Libro } from '../components/Libro/Libro'
import { Marketplace } from '../components/Marketplace/Marketplace'
import { Memorias } from '../components/Memorias/Memorias'
import { NotFound } from '../components/NotFound'
import { Panel } from '../components/Panel'
import { Planos } from '../components/Planos/Planos'
import { Requerimientos } from '../components/Requerimientos/Requerimientos'
import { Carrito } from '../components/TiendaVirtual/Carrito'
import { Categorias } from '../components/TiendaVirtual/Categorias'
import { PagarForma } from '../components/TiendaVirtual/PagarForma'
import { PagarPSE } from '../components/TiendaVirtual/PagarPSE'
import { PagarTC } from '../components/TiendaVirtual/PagarTC'
import { ProductoDetail } from '../components/TiendaVirtual/ProductoDetail'
import { Productos } from '../components/TiendaVirtual/Productos'
import { Respuesta } from '../components/TiendaVirtual/Respuesta'
import { Videos } from '../components/Videos/Videos'
import { app } from '../firebase/firebaseConfig'
import { DashboardRoutes } from './DashboardRoutes'
import { InnerContent } from './InnerContent'
import { PrivateRoutes } from './PrivateRoutes'
import { PublicRoutes } from './PublicRoutes'

export const AppRouter = () => {

  const dispatch = useDispatch();

  const [checking, setChecking] = useState(true);
  const [isLoggedIn, setisLoggedIn] = useState(false);

  useEffect(() => {
    app.auth().onAuthStateChanged(     user => {
      if (user?.uid) {
        dispatch(login(user.uid, user.displayName, user.email));
        setisLoggedIn(true);

        /*const notes = loadNotes(user.uid);

        dispatch(setNotes(notes));*/

        
      }
      else{
        setisLoggedIn(false);
      }

      setChecking(false);
    })
  }, [dispatch, setChecking, setisLoggedIn])
  
  if(checking){
    return <div>Checking...</div>
  }


  return (
    <div>
      <Routes>
       
       <Route path='/auth/login' element={
         <PublicRoutes isLoggedIn={isLoggedIn} >  
           <LoginScreen />
         </PublicRoutes>
       } >
       </Route> 
 
 
       <Route path='/auth/register' element={
         <PublicRoutes isLoggedIn={isLoggedIn} >  
           <RegisterScreen />
         </PublicRoutes>
       } >
         
 
       </Route> 

       {/* <Route path='/respuesta' element={<Respuesta />} ></Route>
       <Route path="not-found" element={<NotFound />} />
       <Route path="*" element={<Navigate to="not-found" />} /> */}
 
        <Route path='/*' element={
           <PrivateRoutes isLoggedIn={isLoggedIn}>
               <DashboardRoutes />
           </PrivateRoutes>
         }>          
        </Route>  
           
       </Routes>
    </div>
  )
}
