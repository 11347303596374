import { types } from '../types/types';

export const setProductosMemory = (productos) => ({
    type: types.tiendaProductosCategoriaLoad,
    payload: productos
});

export const setAdicionarValorPedido = (valor) => ({
    type: types.tiendaAdicionarValorPedido,
    payload: valor
});

export const setReducirValorPedido = (valor) => ({
    type: types.tiendaReducirValorPedido,
    payload: valor
});

export const setLimpiarPedido = () => ({
    type: types.tiendaLimpiarPedido,
    payload: []
});

export const setLimpiarProductos = () => ({
    type: types.tiendaLimpiarProductos,
    payload: []
});

export const setLimpiarValorPedido = () => ({
    type: types.tiendaLimpiarValorPedido,
    payload: 0
});

export const setLimpiarDatosPago = () => ({
    type: types.tiendaLimpiarDatosPago,
    payload: null
});

export const setLimpiarDatosEnvio = () => ({
    type: types.tiendaLimpiarDatosEnvio,
    payload: null
});

export const setLimpiarProductoActive = () => ({
    type: types.tiendaLimpiarProductoActive,
    payload: null
});

export const setLimpiarCategoriaActive = () => ({
    type: types.tiendaLimpiarCategoriaActive,
    payload: null
});

export const setCategoriaActive = (categoriaActive) => ({
    type: types.tiendaCategoriaActive,
    payload: categoriaActive
});

export const setDatosPago = (datosPago) => ({
    type: types.tiendaAdicionarDatosPago,
    payload: datosPago
});

export const setDatosEnvio = (datosEnvio) => ({
    type: types.tiendaAdicionarDatosEnvio,
    payload: datosEnvio
});

export const setProductoActive = (productoActive) => ({
    type: types.tiendaProductoActive,
    payload: {
        ...productoActive
    }
});

export const setAdicionarProducto = (producto) => ({
    type: types.tiendaAdicionarProducto,
    payload: {
        ...producto
    }
});

export const setEliminarProducto = (codigo) => ({
    type: types.tiendaEliminarProducto,
    payload: codigo    
});