
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { startLogout } from '../actions/auth';
import { setLimpiarCategoriaActive, setLimpiarDatosEnvio, setLimpiarDatosPago, setLimpiarPedido, setLimpiarProductoActive, setLimpiarProductos, setLimpiarValorPedido } from '../actions/productos';
import { Cotizaciones } from '../components/Cotizaciones/Cotizaciones';
import { Libro } from '../components/Libro/Libro';
import { Marketplace } from '../components/Marketplace/Marketplace';
import { Memorias } from '../components/Memorias/Memorias';
import { NotFound } from '../components/NotFound';
import { Panel } from '../components/Panel';
import { Planos } from '../components/Planos/Planos';
import { Requerimientos } from '../components/Requerimientos/Requerimientos';
import { Carrito } from '../components/TiendaVirtual/Carrito';
import { Categorias } from '../components/TiendaVirtual/Categorias';
import { PagarForma } from '../components/TiendaVirtual/PagarForma';
import { PagarPSE } from '../components/TiendaVirtual/PagarPSE';
import { PagarTC } from '../components/TiendaVirtual/PagarTC';
import { ProductoDetail } from '../components/TiendaVirtual/ProductoDetail';
import { Productos } from '../components/TiendaVirtual/Productos';
import { Respuesta } from '../components/TiendaVirtual/Respuesta';
import { DatosEmpresa } from '../components/Cotizaciones/DatosEmpresa';
import { Videos } from '../components/Videos/Videos';
import { InnerContent } from './InnerContent';
import { DatosClientes } from '../components/Cotizaciones/DatosClientes';
import { ConfigurarCotizacion } from '../components/Cotizaciones/ConfigurarCotizacion';
import { CrearCotizacion } from '../components/Cotizaciones/CrearCotizacion';
import { MisCotizaciones } from '../components/Cotizaciones/MisCotizaciones';
import { MarketplaceProductos } from '../components/Marketplace/MarketplaceProductos';
import { MarketplaceProductoDetalle } from '../components/Marketplace/MarketplaceProductoDetalle';
import { ImpresionCotizacion } from '../components/Cotizaciones/ImpresionCotizacion';

export const DashboardRoutes = () => {

    const dispatch = useDispatch();
    const {name} = useSelector( state => state.auth );

    const handleLogout = () => {
        localStorage.removeItem('pedido');
        localStorage.removeItem('datosEnvio');
        localStorage.removeItem('datosPago');
        dispatch(setLimpiarPedido());
        dispatch(setLimpiarValorPedido());
        dispatch(setLimpiarDatosPago());
        dispatch(setLimpiarDatosEnvio());
        dispatch(setLimpiarCategoriaActive());
        dispatch(setLimpiarProductoActive());
        dispatch(setLimpiarProductos());
        dispatch(startLogout());
    }


  return (
    <div>
        {/* <aside className='app__sidebar'>
            <div className='app__sidebar-navbar'>
                <p><b>Hola,</b> {name}!!!</p>
            </div>            
            <button
                className="btn btn-primary btn-block"
                onClick={handleLogout}
            >Salir</button>

            <div>
                <p onClick={handleMisProyectos} className='app__sidebar-menu'>¿Como Lograr mis proyectos?</p>
                <p onClick={handleVideos} className='app__sidebar-menu'>Videos Curso de Carpinteria</p>
                <p className='app__sidebar-menu'>Libro de Carpinteria</p>
                <p className='app__sidebar-menu'>Tienda Virtual</p>
                <p className='app__sidebar-menu'>Marketplace</p>
                <p className='app__sidebar-menu'>Necesito un carpintero</p>
                <p className='app__sidebar-menu'>Planos</p>
                <p className='app__sidebar-menu'>Cotizaciones</p>
            </div>

            
        </aside> */}
        
        <div className='app__main-content'>
            <div className='app__sidebar-navbar'>
                <p>Hola, <b>{name}</b>!!!</p>
                
            </div>    
              
            {/* <Link style={{'color':'white', 'marginLeft':'20px'}} to={`/carrito`}>Ver carrito</Link>   */}
            <button
                className="btn btn-primary "
                onClick={handleLogout}
                >Salir
            </button>
             
        </div>
            
        
        



        <Routes>
                <Route path="panel" element={<Panel />} />
                <Route path="memorias" element={<Memorias />} />
                <Route path="categorias" element={<Categorias />} />
                <Route path="productos/:categoriaId" element={<Productos />} />
                <Route path="productoDetalle/:codigo" element={<ProductoDetail />} />
                <Route path="carrito" element={<Carrito />} />
                <Route path="pagarForma" element={<PagarForma />} />
                <Route path="pagarPSE" element={<PagarPSE />} />
                <Route path="pagarTC" element={<PagarTC />} />
                <Route path="videos" element={<Videos />} />
                <Route path="libro" element={<Libro />} />
                <Route path="marketplace" element={<Marketplace />} />
                <Route path="requerimientos" element={<Requerimientos />} />
                <Route path="planos" element={<Planos />} />
                <Route path="cotizaciones" element={<Cotizaciones />} />
                <Route path="impresionCotizacion" element={<ImpresionCotizacion />} />
                <Route path="datosEmpresa" element={<DatosEmpresa />} />
                <Route path="datosClientes" element={<DatosClientes />} />
                <Route path="configurarCotizacion" element={<ConfigurarCotizacion />} />
                <Route path="crearCotizacion" element={<CrearCotizacion />} />
                <Route path="misCotizaciones" element={<MisCotizaciones />} />
                <Route path="marketplaceProductos/:categoriaId" element={<MarketplaceProductos />} />
                <Route path="marketplaceProductoDetalle/:codigo" element={<MarketplaceProductoDetalle />} />
                <Route path="respuesta" element={<Respuesta />} /> 
                <Route path="not-found" element={<NotFound />} />
                <Route path="*" element={<Navigate to="not-found" />} />
           
        </Routes>

        {/* <Routes>
                <Route path="/" element={<InnerContent />} >
                    <Route path="/" element={<Navigate replace to="panel" />} />
                    <Route path="panel" element={<Panel />} />
                    <Route path="memorias" element={<Memorias />} />
                    <Route path="categorias" element={<Categorias />} />
                    <Route path="productos/:categoriaId" element={<Productos />} />
                    <Route path="productoDetalle/:codigo" element={<ProductoDetail />} />
                    <Route path="carrito" element={<Carrito />} />
                    <Route path="pagarForma" element={<PagarForma />} />
                    <Route path="pagarPSE" element={<PagarPSE />} />
                    <Route path="pagarTC" element={<PagarTC />} />
                    <Route path="videos" element={<Videos />} />
                    <Route path="libro" element={<Libro />} />
                    <Route path="marketplace" element={<Marketplace />} />
                    <Route path="requerimientos" element={<Requerimientos />} />
                    <Route path="planos" element={<Planos />} />
                    <Route path="cotizaciones" element={<Cotizaciones />} />
                    <Route path="respuesta" element={<Respuesta />} />
                </Route>
        </Routes> */}
            
      
    </div>
  )
}
