import React from 'react';
import { Provider } from 'react-redux';
import {store} from './store/store';
import {AppRouter} from './routers/AppRouter';



function App() {
  

  return (
    <div >

      <Provider store={store}>
        <AppRouter />
      </Provider>

      {/* <BrowserRouter>
        <Routes>
          <Route path="/LograrMisProyectos" element={<LograrMisProyectos />} />
          <Route path="/TiendaVirtual" element={<Categorias />} />
          <Route path="/" element={<HagaloApp />} />
        </Routes>
      
      </BrowserRouter> */}

        
        

    </div>
  );
}

export default App;
