import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { child, update, getDatabase, onValue, ref } from "firebase/database";
import {Container, Grid, TextField}  from '@mui/material'
import {Buffer} from 'buffer';
import Swal from 'sweetalert2';

export const DatosEmpresa = () => {

  const {email} = useSelector( state => state.auth );
  const db = getDatabase();
  let buff = new Buffer(email);
  let stringToBase64 = buff.toString('base64');
    

  const initialValuesEmpresa = {'Nombre': '', 'Nit': '', 'Direccion': '', 'Telefono': '', 'Celular': '', 'Correo': '', 'Ciudad': '', 'AsesorComercial':''};
  const [datosEmpresa, setDatosEmpresa] = useState(initialValuesEmpresa);
  const [key, setKey] = useState('');

  const handleChangeEnvio = (event) => {
    setDatosEmpresa({...datosEmpresa, [event.target.name]: event.target.value});
    console.log(datosEmpresa);
    //console.log([event.target.name]);
    //console.log(event.target.value);
    //console.log(datosEnvio);
  }

  const handleGuardar = (event) => {
    event.preventDefault();
    if(datosEmpresa.Nombre.length === 0){
      Swal.fire("Advertencia", "Debe ingresar el nombre de la empresa o negocio", "warning");
      return;
    }

    if(datosEmpresa.Nit.length === 0){
      Swal.fire("Advertencia", "Debe ingresar el numero de identificación", "warning");
      return;
    }

    if(datosEmpresa.Direccion.length === 0){
      Swal.fire("Advertencia", "Debe ingresar la dirección", "warning");
      return;
    }

    if(datosEmpresa.Telefono.length === 0){
      Swal.fire("Advertencia", "Debe ingresar el teléfono", "warning");
      return;
    }

    if(datosEmpresa.Celular.length === 0){
      Swal.fire("Advertencia", "Debe ingresar el celular", "warning");
      return;
    }

    if(datosEmpresa.Correo.length === 0){
      Swal.fire("Advertencia", "Debe ingresar el correo electrónico", "warning");
      return;
    }

    if(datosEmpresa.Ciudad.length === 0){
      Swal.fire("Advertencia", "Debe ingresar la ciudad", "warning");
      return;
    }

    if(datosEmpresa.AsesorComercial.length === 0){
      setDatosEmpresa({...datosEmpresa, AsesorComercial: "sin datos"});
    }

    update(child(ref(db),`/Empresas/${stringToBase64}/${key}`), datosEmpresa);

    Swal.fire("Guardado", "Los datos de tu empresa se han guardado con exito", "success");
  }

  useEffect(() => {

    

    
    const empresaRef = child(ref(db),`/Empresas/${stringToBase64}`);
    
    onValue(empresaRef, (snapshot) => {
      
      for(let key in snapshot.val()){
        //console.log("Snapshot Key", key);
        setKey(key);
      }
      snapshot.forEach(empresa => {        
        setDatosEmpresa(empresa.val());
      });
      //console.log("Datos Empresa", snapshot.val());
    });

    //console.log("String email", stringToBase64);

  } , [email, db, stringToBase64]);

  return (
    <div>
      
      <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link to={`/cotizaciones`} className="btn-return">Volver a Cotizaciones</Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top"  />
        <h1>Datos de la empresa</h1>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <img src={datosEmpresa.UrlLogo} alt="Logo Empresa" style={{width:"100%", height:"360px"}} />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Nombre" variant='outlined' fullWidth name="Nombre" onChange={handleChangeEnvio} value={datosEmpresa.Nombre}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Nit/CC" variant='outlined' fullWidth name="Nit" onChange={handleChangeEnvio} value={datosEmpresa.Nit}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Dirección" variant='outlined' fullWidth name="Direccion" onChange={handleChangeEnvio} value={datosEmpresa.Direccion}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Teléfono" variant='outlined' fullWidth name="Telefono" onChange={handleChangeEnvio} value={datosEmpresa.Telefono}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Celular" variant='outlined' fullWidth name="Celular" onChange={handleChangeEnvio} value={datosEmpresa.Celular}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Correo" variant='outlined' fullWidth name="Correo" onChange={handleChangeEnvio} value={datosEmpresa.Correo}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Ciudad" variant='outlined' fullWidth name="Ciudad" onChange={handleChangeEnvio} value={datosEmpresa.Ciudad}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <TextField label="Asesor Comercial" variant='outlined' fullWidth name="AsesorComercial" onChange={handleChangeEnvio} value={datosEmpresa.AsesorComercial}  >

              </TextField>
            </Grid>
            
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <button className="btn btn-primary" onClick={handleGuardar}>
                Guardar Datos Empresa
              </button>
            </Grid>
          
        </Grid>
      </Container>


    </div>

  )
}
