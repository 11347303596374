import { types } from "../types/types";

const initialState = { 
    cotizacionActive:""
}

export const impresionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.impresionCotizacionActive:
            return{
                ...state,
                cotizacionActive: action.payload
            }
        default:
            return state;
    }
}