import { Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const ProductoCard = ({CategoriaCodigo, Codigo, Nombre, Notas, PorcentajeDescuento, Precio, UrlImagen}) => {
  return (
    <div className="card animate__animated animate__fadeIn">
          <Grid container spacing={0}>
            <Grid item xs={3} md={2} xl={1}>
              <img src={UrlImagen} alt="Logo" height={60} width={60}></img>
            </Grid>
            <Grid item xs={9} md={10} xl={11}>
              <h3 className="title">{Nombre}</h3>
              {/* <a hrer="#" target="_blank" 
              >Ver detalle</a> */}
              <Link to={`/productoDetalle/${Codigo}`}>Ver detalle</Link>
            </Grid>
            
          </Grid>
          
          
        </div>
  )
}
