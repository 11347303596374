import Swal from "sweetalert2";
import { app, appleProvider, googleProvider } from "../firebase/firebaseConfig";
import { types } from "../types/types"
// import { notesLogout } from "./notes";
import { finishLoading, startLoading } from "./ui";

export const startLoginEmailPassword = ( email, password ) => {
    return (dispatch) => {

        dispatch(startLoading());
        
        app.auth().signInWithEmailAndPassword(email, password)
        .then( ({user}) =>{          

            dispatch(login(user.uid, user.displayName, user.email));

            dispatch(finishLoading());

        }).catch(err => {
            console.log(err)
            dispatch(finishLoading());
            Swal.fire('Error', err.message, 'error');
        });
        


        // setTimeout(() => {
        //     dispatch(login(1245,'Yovany'));
        // }, 3500);
    }
} 

export const startRegisterWithEmailPasswordName = ( email, password, name ) => {
    return (dispatch) => {
        app.auth().createUserWithEmailAndPassword(email, password)
        .then(async ({user}) =>{
            
            await user.updateProfile({displayName: name});
            console.log(user);

            dispatch(login(user.uid, user.displayName, user.email));

        }).catch(err => {
            console.log(err)
            Swal.fire('Error', err.message, 'error');
        });
    }
}

export const startGoogleLogin = () => { 
    return (dispatch) => {
      
           app.auth().signInWithPopup(googleProvider)
            .then(({user}) =>{
                dispatch(login(user.uid, user.displayName, user.email));

            })
    }
}

export const startAppleLogin = () => {
    return (dispatch) => {
        app.auth().signInWithPopup(appleProvider)
        .then(({user}) =>{
            dispatch(login(user.uid, user.displayName, user.email));

        })
    }
}


export const login = (uid, displayName, email) => {
    return {
        type: types.login,
        payload: {
            uid,
            displayName,
            email
        }
    }
}

export const startLogout = (uid, displayName) => {
    return async(dispatch) => {
       await app.auth().signOut();

       dispatch(logout());

       //dispatch(notesLogout());
    }
}

export const logout = () => ({
    type: types.logout
});