
import { Container} from "@mui/material"
import { child, getDatabase, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MemoriaCard } from "./MemoriaCard";


export const Memorias = () => {

  
  const initialValues = [{"Codigo":"","Descripcion":"","UrlDocumento":""}];

 

  const [notas, setNotas] = useState(initialValues);

  useEffect(() => {
    const db = getDatabase();
    const notesRef = child(ref(db),`Memorias`);

    const notes = [];
    
    onValue(notesRef, (snapshot) => {
       
        snapshot.forEach(note => {            
            notes.push(note.val());            
        });

        setNotas(notes);
        console.log(notes);
        
    })
  }, [setNotas]);

 
  return (

    <>        
        
        <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link 
              to="/panel"
              className="btn-return"
          >
             Volver  
        </Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top" />
        
        <h1 className="title">¿Cómo lograr mis proyectos?</h1>
        <p className="subtitle">Acá podrás acceder a material de gran utilidad para profundizar tus conocimientos, conoce más en los siguientes enlaces</p>
        <hr className="space"/>
        
        {
          notas.map( note => (
            <MemoriaCard 
            key={note.Codigo}
            {...note} />
          ))
        
        }


        {/* <div className="card">
          <Grid container spacing={0}>
            <Grid item xs={3} md={2} xl={1}>
              <img src={logo} alt="Logo" height={60} width={60}></img>
            </Grid>
            <Grid item xs={9} md={10} xl={11}>
              <h3 className="title">Memorias del Curso 1</h3>
              <a href="https://alamanooriente.com/FilesHeC/Memorias/MEMORIAS%20DEL%20CURSO%20PRESENCIAL%20CARPINTERIA_removed-1-28.pdf" 
              rel="noreferrer" 
              target="_blank"
              className="link card__link-internal"
              >Ver documento</a>
            </Grid>
            
          </Grid>
          
          
        </div>

        <div className="card">

        <Grid container spacing={0}>
            <Grid item xs={3} md={2} xl={1}>
              <img src={logo} alt="Logo" height={60} width={60}></img>
            </Grid>
            <Grid item xs={9} md={10} xl={11}>
              <h3 className="title">Memorias del Curso 2</h3>
              <a href="https://alamanooriente.com/FilesHeC/Memorias/MEMORIAS%20DEL%20CURSO%20PRESENCIAL%20CARPINTERIA_removed-29-58.pdf" 
              rel="noreferrer" 
              target="_blank"
              className="link card__link-internal"
              >Ver documento</a>
            </Grid>
            
          </Grid>
          
          </div>

          <div className="card">

        <Grid container spacing={2}>
            <Grid item xs={3} md={2} xl={1}>
              <img src={logo} alt="Logo" height={60} width={60}></img>
            </Grid>
            <Grid item xs={9} md={10} xl={11}>
              <h3 className="title">Memorias del Curso 3</h3>
              <a href="https://alamanooriente.com/FilesHeC/Memorias/MEMORIAS%20DEL%20CURSO%20PRESENCIAL%20CARPINTERIA_removed-29-58.pdf" 
              rel="noreferrer" 
              target="_blank"
              className="link card__link-internal"
              >Ver documento</a>
            </Grid>
            
          </Grid>
          
          </div> */}

        

    </Container>
    </>
    
  )
}
