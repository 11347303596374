import { types } from "../types/types";


const initialState = { 
    categoriaActive:"",
    productoActive:null,
    productos: [],
    pedido: [],
    valorPedido: 0,
    datosPago:null,
    datosEnvio:null
}

export const tiendaReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.tiendaAdicionarValorPedido:
            return{
                ...state,
                valorPedido: parseFloat(state.valorPedido) + parseFloat(action.payload)
            }
        case types.tiendaReducirValorPedido:
            return{
                ...state,
                valorPedido: parseFloat(state.valorPedido) - parseFloat(action.payload)
            }
        case types.tiendaCategoriaActive:
            return{
                ...state,
                categoriaActive: action.payload
            }
        case types.tiendaProductoActive:
            return{
                ...state,
                productoActive: {
                    ...action.payload
                }
            }
        case types.tiendaProductosCategoriaLoad:
            return{
                ...state,
                productos: [...action.payload]
            }
        case types.tiendaAdicionarProducto:
            return{
                ...state,
                pedido:[...state.pedido, action.payload]
            }
        case types.tiendaEliminarProducto:
            return{
                ...state,
                pedido: state.pedido.filter(item => item.Codigo !== action.payload)
            }
        case types.tiendaLimpiarPedido:
            return{
                ...state,
                pedido: [],
            }
        case types.tiendaLimpiarValorPedido:
            return{
                ...state,
                valorPedido: 0,
            }
        case types.tiendaAdicionarDatosPago:
            return{
                ...state,
                datosPago: action.payload
            }
        case types.tiendaAdicionarDatosEnvio:
            return{
                ...state,
                datosEnvio: action.payload
            }
        case types.tiendaLimpiarDatosPago:
            return{
                ...state,
                datosPago: null,
            }
        case types.tiendaLimpiarDatosEnvio:
            return{
                ...state,
                datosEnvio: null,
            }
        case types.tiendaLimpiarCategoriaActive:
            return{
                ...state,
                categoriaActive: "",
            }
        case types.tiendaLimpiarProductoActive:
            return{
                ...state,
                productoActive: null,
            }
        case types.tiendaLimpiarProductos:
            return{
                ...state,
                productos: [],
            }
        default:
            return state;
        }
}