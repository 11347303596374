import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { child, getDatabase, onValue, ref } from "firebase/database";
import { Container } from "@mui/material";
import { ProductoCard } from "./ProductoCard";
import { useDispatch } from "react-redux";
import { setCategoriaActive, setProductosMemory } from "../../actions/productos";


export const Productos = () => {

  const {categoriaId} = useParams();




  const initialValues = [{'CategoriaCodigo':"", 'Codigo':"", 'Nombre':"", 'PorcentajeDescuento':"",'Notas':"", 'Precio':0,'UrlImagen':""}];

  const [productos, setProductos] = useState(initialValues);

  const dispatch = useDispatch();

  useEffect(() => {
    const db = getDatabase();
    const productsRef = child(ref(db),`Productos`);

    const products = [];
    
    dispatch(setCategoriaActive(categoriaId));

    onValue(productsRef, (snapshot) => {
       
        snapshot.forEach(product => {            
          products.push(product.val());            
        });

        
        setProductos(products.filter(product => product.CategoriaCodigo === categoriaId));
        dispatch(setProductosMemory(products.filter(product => product.CategoriaCodigo === categoriaId)));
        console.log(products.filter(product => product.CategoriaCodigo === categoriaId));
        
    })
  }, [setProductos, dispatch, categoriaId]);

  console.log(`categoria: ${categoriaId}`);

  return (
    <Container fixed>
       <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link 
              to="/categorias"
              className="btn-return"
          >
             Volver a Categorias   
        </Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top"  />
        <h1 className="title">Tienda Virtual - Productos Categoria </h1>
        <p className="subtitle">Acá podrás solicitar productos y/o servicios de carpinteria</p>
        <hr className="space"/>

        {
          productos.map( producto => (
            <ProductoCard 
            key={producto.Codigo}
            {...producto} />
          ))
        
        }
    </Container>
  )
}
