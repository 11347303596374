import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getResponseTransaction } from '../../helpers/getResponseTransaction';
import { getDatabase, set, ref, child, push, update } from 'firebase/database'
import { useSelector } from 'react-redux';

export const Respuesta = () => {

    //const {ref_payco} = useParams();
    const [searchParameters, setSearchParameters] = useSearchParams();
   const data = searchParameters.get('ref_payco');
    const [pedido, setPedido] = useState([]);
   const [response, setResponse] = useState({x_response: '', x_cod_response: '',
    x_ref_payco : '', x_transaction_date: '', x_transaction_id: '', x_id_factura:'' ,x_amount: 0, x_approval_code: ''});
    const {email} = useSelector( state => state.auth );
    

    // let {ref_payco_response} = searchParameters.get("ref_payco").replace('ref_payco','');
   //const data = JSON.parse(searchParameters.get("ref_payco")); 
   //setSearchParameters(searchParameters.replace('ref_payco',''));

  //  useEffect(() => {

  //   var datosEnvio = localStorage.getItem('datosEnvio') ? JSON.parse(localStorage.getItem('datosEnvio')) : {};

  //   console.log("Nombre Envio", datosEnvio.NombreEnvio);


  //  }, []);
    
   useEffect(() => {

    getResponseTransaction(data)
    .then(response => {
            console.log(response);
            setResponse(response);

            var dataPedido = localStorage.getItem('pedido') 
            setPedido(JSON.parse(dataPedido));

            var datosEnvio = localStorage.getItem('datosEnvio') ? JSON.parse(localStorage.getItem('datosEnvio')) : {};
            var datosPago = localStorage.getItem('datosPago') ? JSON.parse(localStorage.getItem('datosPago')) : {};

            var datosPedido = localStorage.getItem('pedido') ? JSON.parse(localStorage.getItem('pedido')) : {};

            if(response.x_response === "Aceptada"){
              //Todo: Enviar a la base de datos

              // var dataPedido = localStorage.getItem('pedido') 
              // setPedido(JSON.parse(dataPedido));

              // var datosEnvio = localStorage.getItem('datosEnvio') ? JSON.parse(localStorage.getItem('datosEnvio')) : {};
              // var datosPago = localStorage.getItem('datosPago') ? JSON.parse(localStorage.getItem('datosPago')) : {};

              // var datosPedido = localStorage.getItem('pedido') ? JSON.parse(localStorage.getItem('pedido')) : {};
              //console.log("datosPedido", datosPedido);

              let detallePedido = "";
              let modulosPedido = "";

              let modulos = [];
      
              datosPedido.forEach(element => {
                  detallePedido += "Nueva Linea => Nombre= " + element.Nombre + 
                    ", Cantidad= " + element.Cantidad + ", Valor Unitario= " + element.Precio + 
                    ", Valor Total= " + element.Cantidad * element.Precio + ". || ";

                    if(element.Nombre === "Libro Digital")
                    {
                      modulos.push("Libro");
                    }
                    else if(element.Nombre === "Club de Planos")
                    {
                      modulos.push("Planos");
                    }
              });

              //Guardar en la base de datos
              const db = getDatabase();

              modulos.forEach(element => {
                modulosPedido += element + " || "

                
                const newPermiso = {
                  Email: email,
                  Nombre: datosPago.NombrePago
                }

                if(element === "Libro")
                {
                  const newKeyPermisoLibro = push(child(ref(db),`/Permisos/Libro`)).key;
                  update(child(ref(db),`/Permisos/Libro/${newKeyPermisoLibro}`), newPermiso);
                }
                else if(element === "Planos")
                {
                  const newKeyPermisoPlanos = push(child(ref(db),`/Permisos/Planos`)).key;
                  update(child(ref(db),`/Permisos/Planos/${newKeyPermisoPlanos}`), newPermiso);
                }
              });
      
              console.log("detallePedido", detallePedido);              

              
          
              const newKey = push(child(ref(db),`/Transacciones/`)).key;
              const newPedido = {
                DetallePedido : detallePedido,
                EnvioCelular:datosEnvio.CelularEnvio,
                EnvioCiudad:datosEnvio.CiudadEnvio,
                EnvioDireccion:datosEnvio.DireccionEnvio,
                EnvioEmail:datosEnvio.EmailEnvio,
                EnvioNombreCompleto:datosEnvio.NombreEnvio,
                EnvioNotas:datosEnvio.NotasEnvio,
                EnvioTelefono:datosEnvio.TelefonoEnvio,
                Fecha:response.x_transaction_date,
                Modulo:modulosPedido,
                TransaccionId: response.x_transaction_id,
                address: datosPago.DireccionPago,
                autorizacion:"",
                cellPhone: datosPago.CelularPago,
                docNumber: datosPago.NumeroDocumentoPago,
                email: email,
                factura: response.x_id_factura,
                fechaPago: response.x_transaction_date,
                lastName: datosPago.NombrePago,
                name: datosPago.NombrePago,
                phone: datosPago.TelefonoPago,
                recibo: "",
                ref_payco: response.x_ref_payco,
                ticketId: "",
                transactionID: response.x_transaction_id,
                value:response.x_amount

              }

              update(child(ref(db),`/Transacciones/${newKey}`), newPedido);


              localStorage.removeItem('pedido');
              localStorage.removeItem('datosEnvio');
              localStorage.removeItem('datosPago');

              Swal.fire("Transacción Aceptada", "Se ha registrado tu pedido, pronto nos pondremos en contacto contigo", "success");
            }
            else if(response.x_response === "Pendiente")
            {
              
              
              //console.log("datosPedido", datosPedido);
              let detallePedido = "";
              let modulosPedido = "";

              let modulos = [];
      
              datosPedido.forEach(element => {
                  detallePedido += "Nueva Linea => Nombre= " + element.Nombre + 
                    ", Cantidad= " + element.Cantidad + ", Valor Unitario= " + element.Precio + 
                    ", Valor Total= " + element.Cantidad * element.Precio + ". || ";

                    if(element.Nombre === "Libro Digital")
                    {
                      modulos.push("Libro");
                    }
                    else if(element.Nombre === "Club de Planos")
                    {
                      modulos.push("Planos");
                    }
              });

              //Guardar en la base de datos
              const db = getDatabase();

              modulos.forEach(element => {
                modulosPedido += element + " || "

                
                const newPermiso = {
                  Email: email,
                  Nombre: datosPago.NombrePago
                }

                if(element === "Libro")
                {
                  const newKeyPermisoLibro = push(child(ref(db),`/Permisos/Libro`)).key;
                  update(child(ref(db),`/Permisos/Libro/${newKeyPermisoLibro}`), newPermiso);
                }
                else if(element === "Planos")
                {
                  const newKeyPermisoPlanos = push(child(ref(db),`/Permisos/Planos`)).key;
                  update(child(ref(db),`/Permisos/Planos/${newKeyPermisoPlanos}`), newPermiso);
                }
              });
      
              console.log("detallePedido", detallePedido);              

              
          
              const newKey = push(child(ref(db),`/Transacciones/`)).key;
              const newPedido = {
                DetallePedido : detallePedido,
                EnvioCelular:datosEnvio.CelularEnvio,
                EnvioCiudad:datosEnvio.CiudadEnvio,
                EnvioDireccion:datosEnvio.DireccionEnvio,
                EnvioEmail:datosEnvio.EmailEnvio,
                EnvioNombreCompleto:datosEnvio.NombreEnvio,
                EnvioNotas:datosEnvio.NotasEnvio,
                EnvioTelefono:datosEnvio.TelefonoEnvio,
                Fecha:response.x_transaction_date,
                Modulo:modulosPedido,
                TransaccionId: response.x_transaction_id,
                address: datosPago.DireccionPago,
                autorizacion:"",
                cellPhone: datosPago.CelularPago,
                docNumber: datosPago.NumeroDocumentoPago,
                email: email,
                factura: response.x_id_factura,
                fechaPago: response.x_transaction_date,
                lastName: datosPago.NombrePago,
                name: datosPago.NombrePago,
                phone: datosPago.TelefonoPago,
                recibo: "",
                ref_payco: response.x_ref_payco,
                ticketId: "",
                transactionID: response.x_transaction_id,
                value:response.x_amount

              }

              update(child(ref(db),`/Transacciones/${newKey}`), newPedido);


              localStorage.removeItem('pedido');
              localStorage.removeItem('datosEnvio');
              localStorage.removeItem('datosPago');

              Swal.fire("Transacción Pendiente", "Se ha registrado tu pedido, sin embargo por estar en estado pendiente tu pago queda sujeto a aprobación para habilitar o despachar tu pedido", "warning");
            }
            else if(response.x_response === "Rechazada"){
              Swal.fire("Transacción Rechazada", "Se ha rechazado el pago", "error");
            }
            else{
              Swal.fire("Transacción desconocida", "Si estabas haciendo un pedido y el pago fue debitado de tu cuenta, envianos el comprobante de pago y conunicate con nosotros", "error");
            }

        });

   }, [data, email]);

   useEffect(() => {
    
    }, [])

  return (
    <Container>
        <Box sx={{ paddingTop: '20px' }} />
        <Link to={`/panel`}>Volver al Inicio</Link>
        <Box sx={{ paddingTop: '20px' }} />
        <Typography variant="h5" component="h6">
          Resultado de la Transacción
        </Typography>
        <Box sx={{ paddingTop: '20px' }} />
        <p><b>Estado de la transacción: </b> {response.x_response}</p>
        <p><b>Código de Referencia: </b> {response.x_ref_payco}</p>
        <p><b>Código de Aprobación: </b> {response.x_approval_code}</p>
        <p><b>Fecha y Hora: </b> {response.x_transaction_date}</p>
        <p><b>Valor: </b> {response.x_amount}</p>
        <br />
        <h3>Este es el detalle de tu pedido</h3>
        
          {
            pedido.map((pedido) => (
              <div key={pedido.Codigo}>
                  <p><b>Producto: </b> {pedido.Nombre}</p>
                  <p><b>Precio: </b> {pedido.Precio}</p>
                  <p><b>Cantidad: </b> {pedido.Cantidad}</p>
                </div>
            ))
          }
        
        
    </Container>
  )
}
