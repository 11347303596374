import { Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { CarritoItem } from './CarritoItem'
import { EpaycoButton } from './EpaycoButton'

export const Carrito = () => {

    //const dispatch = useDispatch()
    const initialValuesPago = {'EmailPago':"",'NombrePago':"",'CiudadPago':"",'DireccionPago':"",
    'TipoDocumentoPago':"",'NumeroDocumentoPago':"",'CelularPago':"",'TelefonoPago':""};

    const initialValuesEnvio = {'EmailEnvio':"",'NombreEnvio':"",'CiudadEnvio':"",'DireccionEnvio':"",
    'TipoDocumentoEnvio':"",'NumeroDocumentoEnvio':"",'CelularEnvio':"",'TelefonoEnvio':"", 'NotasEnvio':""};


    const [datosPago, setDatosPago] = useState(initialValuesPago);
    const [datosEnvio, setDatosEnvio] = useState(initialValuesEnvio);

    const{categoriaActive} = useSelector(state => state.tienda);
    const{pedido} = useSelector(state => state.tienda);

    const{valorPedido} = useSelector(state => state.tienda)

    const[visibleEpayco, setVisibleEpayco] = useState(false);

    let total = valorPedido;
    let tituloCompra = "Hágalo en Casa";
    let detalleCompra = "Compra web App - Hagalo en Casa";
    const {email} = useSelector( state => state.auth );

    const handlePagar = () =>{
     
        
        //Swal.fire("Atención", `Visibilidad epayco ${visibleEpayco}`, "info");
        //return;

        // setVisibleEpayco(false);
        // console.log("visible Epayco, ", visibleEpayco);

        var datosPedido = localStorage.getItem('pedido') ? JSON.parse(localStorage.getItem('pedido')) : {};
        //console.log("datosPedido", datosPedido);
        let detallePedido = "";

        datosPedido.forEach(element => {
            detallePedido += "Nueva Linea => Nombre= " + element.Nombre + 
              ", Cantidad= " + element.Cantidad + ", Valor Unitario= " + element.Precio + 
              ", Valor Total= " + element.Cantidad * element.Precio + ". || ";
        });

        console.log("detallePedido", detallePedido);
        
      
        if(datosPago.EmailPago.length === 0){
          Swal.fire("Error", "Debe ingresar un email del pagador", "error");
          return;
        }

        if(datosPago.NombrePago.length === 0){
          Swal.fire("Error", "Debe ingresar un nombre del pagador", "error");
          return;
        }

        if(datosPago.CiudadPago.length === 0){
          Swal.fire("Error", "Debe ingresar una ciudad  del pagador", "error");
          return;
        }

        if(datosPago.DireccionPago.length === 0){
          Swal.fire("Error", "Debe ingresar una dirección del pagador", "error");
          return;
        }

        if(datosPago.TipoDocumentoPago.length === 0){
          Swal.fire("Error", "Debe ingresar un tipo de documento del pagador", "error");
          return;
        }

        if(datosPago.NumeroDocumentoPago.length === 0){
          Swal.fire("Error", "Debe ingresar un número de documento del pagador", "error");
          return;
        }

        if(datosPago.CelularPago.length === 0){
          Swal.fire("Error", "Debe ingresar un celular del pagador", "error");
          return;
        }

        if(datosPago.TelefonoPago.length === 0){
          Swal.fire("Error", "Debe ingresar un teléfono del pagador", "error");
          return;
        }
        
        //Validación datos de envio

        if(datosEnvio.EmailEnvio.length === 0){
          Swal.fire("Error", "Debe ingresar un email del destinatario", "error");
          return;
        }

        if(datosEnvio.NombreEnvio.length === 0){
          Swal.fire("Error", "Debe ingresar un nombre del destinatario", "error");
          return;
        }

        if(datosEnvio.CiudadEnvio.length === 0){
          Swal.fire("Error", "Debe ingresar una ciudad del destinatario", "error");
          return;
        }

        if(datosEnvio.DireccionEnvio.length === 0){
          Swal.fire("Error", "Debe ingresar una dirección del destinatario", "error");
          return;
        }

        if(datosEnvio.TipoDocumentoEnvio.length === 0){
          Swal.fire("Error", "Debe ingresar un tipo de documento del destinatario", "error");
          return;
        }

        if(datosEnvio.NumeroDocumentoEnvio.length === 0){
          Swal.fire("Error", "Debe ingresar un número de documento del destinatario", "error");
          return;
        }

        if(datosEnvio.CelularEnvio.length === 0){
          Swal.fire("Error", "Debe ingresar un celular del destinatario", "error");
          return;
        }

        if(datosEnvio.TelefonoEnvio.length === 0){
          Swal.fire("Error", "Debe ingresar un teléfono del destinatario", "error");
          return;
        }

        if(datosEnvio.NotasEnvio.length === 0){
          setDatosEnvio({...datosEnvio, 'EnvioNotas': "Sin Notas"});
        }

        setVisibleEpayco(true);
        scriptLoader();
        //console.log("visible Epayco, ", visibleEpayco);

        //console.log("Pago", datosPago);
        //console.log("Envio", datosEnvio);
        localStorage.setItem('datosPago', JSON.stringify(datosPago));
        localStorage.setItem('datosEnvio', JSON.stringify(datosEnvio));

        Swal.fire("Atención", "Hemos habilitado el botón de pagos, para que puedas proceder con el mismo", "info");
        
        
        //dispatch(setDatosPago(datosPago));
        //dispatch(setDatosEnvio(datosEnvio));        
    }

    const handleChangePago = (event) => {
        setDatosPago({...datosPago, [event.target.name]: event.target.value});
        
        console.log(datosPago);
    }

    const handleChangeEnvio = (event) => {
        setDatosEnvio({...datosEnvio, [event.target.name]: event.target.value});
        //console.log([event.target.name]);
        //console.log(event.target.value);
        //console.log(datosEnvio);
    }

    const handleCheckSinEnvio = (event) => {
        if(event.target.checked){
            //console.log("Sin Envio");
            setDatosEnvio({...datosEnvio, 'EmailEnvio':"Sin datos",'NombreEnvio':"Sin datos",'CiudadEnvio':"Sin datos",'DireccionEnvio':"Sin datos",
            'TipoDocumentoEnvio':"CC",'NumeroDocumentoEnvio':"Sin datos",'CelularEnvio':"Sin datos",'TelefonoEnvio':"Sin datos", 'NotasEnvio':"Sin Notas"});
            //console.log(datosEnvio);
        }
    }

    //handleCheckMismosDatos
    const handleCheckMismosDatos = (event) => {
      if(event.target.checked){
          //console.log("Sin Envio");
          setDatosEnvio({...datosEnvio, 'EmailEnvio':datosPago.EmailPago,'NombreEnvio':datosPago.NombrePago,'CiudadEnvio':datosPago.CiudadPago,'DireccionEnvio':datosPago.DireccionPago,
          'TipoDocumentoEnvio':"CC",'NumeroDocumentoEnvio':datosPago.NumeroDocumentoPago,'CelularEnvio':datosPago.CelularPago,'TelefonoEnvio':datosPago.TelefonoPago});
          //console.log(datosEnvio);
      }
  }

    // var handler = window.ePayco..configure({
    //   key: "02f424e5bc9bc05db74ec3ff548591c9",
    //   test: true
    // });

    const buttonContainerRef = useRef(); // Creating ref element to assign as a form element attribute

    useEffect(() => {
        scriptLoader();
        return () => {
            scriptUnloader(); // Remove the script element on unmount
        };
    }, [])

    const scriptLoader = () => {
        const script = document.createElement("script");
        script.src = "https://checkout.epayco.co/checkout.js";
        script.id = "checkout-button";
        script.async = true;
        document.body.appendChild(script);
        window.addEventListener('load', scriptUnloader) // Runs when all the assets loaded completely
    };

    const scriptUnloader = () => {
        document.body.removeChild(document.querySelector("#checkout-button"));
    }

    const scriptMover = () => {
        const button = document.querySelector(".mercadopago-button"); // Gets the button
        buttonContainerRef.current.appendChild(button) // Appends the button to the ref element, in this case form element
    };

  return (
    <div>
      {/* <PayButton>Epayco 1</PayButton> */}
      {/* <Button onClick={handler}>Epayco</Button> */}



   {/* <form action="/procesar-pago" method="POST" ref={buttonContainerRef} /> */}
  
    <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link to={`/productos/${categoriaActive}`} className="btn-return">Volver a Productos</Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top"  />
        <h1>Estos son los productos en tu carrito actualmente</h1>
        <hr className="space"/>

        {
            pedido.map(producto => (
                <CarritoItem
                    key={producto.Codigo}
                    {...producto} />
            ))

            
        }

        <br />
        <b><p>Costo de envio: A convenir</p></b>
        <br />
        <strong>
          Total del Pedido: 
          <NumberFormat value={valorPedido} displayType={'text'} thousandSeparator={true} prefix={' $ '} />
        </strong>
        <br />
        <br />
        <hr/>
        <br />
        <h2>Datos del Pago</h2>
        <br />
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Email" variant='outlined' fullWidth name="EmailPago" onChange={handleChangePago} >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Nombre Completo" variant='outlined' fullWidth name="NombrePago" onChange={handleChangePago}  >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Ciudad" variant='outlined' fullWidth name="CiudadPago" onChange={handleChangePago}  >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Dirección" variant='outlined' fullWidth name="DireccionPago" onChange={handleChangePago} >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tipo Documento</InputLabel>
              <Select label="TipoDocumento" fullWidth name="TipoDocumentoPago" onChange={handleChangePago}>
              <MenuItem value="">
                <em>Seleccione tipo documento</em>
              </MenuItem>
              <MenuItem value={'CC'}>Cédula de Ciudadania</MenuItem>
              
            </Select>
            </FormControl>
            
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Número Documento" variant='outlined' fullWidth name="NumeroDocumentoPago" onChange={handleChangePago} >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Celular" variant='outlined' fullWidth name="CelularPago" onChange={handleChangePago} >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Télefono" variant='outlined' fullWidth name="TelefonoPago" onChange={handleChangePago} >

            </TextField>
          </Grid>
        </Grid>
        <br />
        <br />
        
        
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <label>
              <input
                          type="checkbox" onChange={handleCheckSinEnvio} /> - 
                     Mi pedido no requiere ser enviado
              
            </label>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
          <label>
            <input
                      type="checkbox" onChange={handleCheckMismosDatos}  /> - 
                 Mis datos de envio son los mismos del Pago
              
          </label>
          </Grid>
        </Grid>
        <br />   
        <br />
        <hr/>
        <br />
        <h2>Datos de Envío</h2>
        <br />
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Email" variant='outlined' fullWidth name="EmailEnvio" onChange={handleChangeEnvio} value={datosEnvio.EmailEnvio}  >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Nombre Completo" variant='outlined' fullWidth name="NombreEnvio" onChange={handleChangeEnvio} value={datosEnvio.NombreEnvio}  >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Ciudad" variant='outlined' fullWidth name="CiudadEnvio" onChange={handleChangeEnvio} value={datosEnvio.CiudadEnvio}>

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Dirección" variant='outlined' fullWidth name="DireccionEnvio" onChange={handleChangeEnvio} value={datosEnvio.DireccionEnvio}>

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tipo Documento</InputLabel>
              <Select label="TipoDocumento" fullWidth name="TipoDocumentoEnvio" onChange={handleChangeEnvio}>
              <MenuItem value="">
                <em>Seleccione tipo documento</em>
              </MenuItem>
              <MenuItem value={'CC'}>Cédula de Ciudadania</MenuItem>
              
            </Select>
            </FormControl>
            
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Número Documento" variant='outlined' fullWidth name="NumeroDocumentoEnvio" onChange={handleChangeEnvio} value={datosEnvio.NumeroDocumentoEnvio} >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Celular" variant='outlined' fullWidth name="CelularEnvio" onChange={handleChangeEnvio} value={datosEnvio.CelularEnvio}>

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Télefono" variant='outlined' fullWidth name="TelefonoEnvio" onChange={handleChangeEnvio} value={datosEnvio.TelefonoEnvio}>

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Notas" variant='outlined' fullWidth name="NotasEnvio" onChange={handleChangeEnvio} value={datosEnvio.NotasEnvio}>

            </TextField>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <button
              onClick={handlePagar}
              className="btn btn-primary"
              >
            Validar datos para pago
          </button>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
          <EpaycoButton visible={visibleEpayco} total={total} tituloCompra={tituloCompra} detalleCompra={detalleCompra}
email={email} NombrePago={datosPago.NombrePago} DireccionPago={datosPago.DireccionPago} TipoDocumentoPago={datosPago.TipoDocumentoPago}
CelularPago={datosPago.CelularPago} NumeroDocumentoPago={datosPago.NumeroDocumentoPago} />
          </Grid>
        </Grid>
        
        <br />
        <br />
{/* ditecno <p>fcdf7e86daf1137e62a16e8010d3309f</p> */}
{/* pruebas <p>491d6a0b6e992cf924edd8d3d088aff1</p> */}


  {/* <p>Visible Epayco: {visibleEpayco.toString()}</p> */}



 { visibleEpayco && <div  >
        {/* <form>
          <script
            src="https://checkout.epayco.co/checkout.js"
            className="epayco-button"
            data-epayco-key="491d6a0b6e992cf924edd8d3d088aff1"
            data-epayco-amount={total}
            data-epayco-name={tituloCompra}
            data-epayco-description={detalleCompra}
            data-epayco-currency="cop"
            data-epayco-country="co"
            data-epayco-test="true"
            data-epayco-external="false"
            data-epayco-response="https://hagaloencasa.co/respuesta"
            data-epayco-confirmation="https://ejemplo.com/confirmacion"
            data-epayco-methodconfirmation="post"
            data-epayco-email-billing={email}
            data-epayco-name-billing={datosPago.NombrePago}
            data-epayco-address-billing={datosPago.DireccionPago}
            data-epayco-type-doc-billing={datosPago.TipoDocumentoPago}
            data-epayco-mobilephone-billing	={datosPago.CelularPago}
            data-epayco-number-doc-billing={datosPago.NumeroDocumentoPago}
          ></script>
        </form> */}
</div> 
  }


        
        
        {/* <Link to={`/pagarForma`}>Pagar</Link>
          <br/>
          <br/>
          <Link to={`/productos/${categoriaActive}`}>Seguir comprando</Link> */}
    
    
    
    </Container>

    </div>
    
  )
}
