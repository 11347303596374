import { Container, Grid, Paper } from "@mui/material"
import { useEffect, useState } from "react";
import { child, getDatabase, onValue, ref } from "firebase/database";
import { Link } from "react-router-dom";
import logo from './../../martillo_logo.jpg';
import { CategoriaCard } from "./CategoriaCard";



export const Categorias = () => {

  const initialValues = [{"Codigo":"","Nombre":""}];

  const [categorias, setCategorias] = useState(initialValues);

  useEffect(() => {
    const db = getDatabase();
    const notesRef = child(ref(db),`CategoriasProducto`);

    const notes = [];
    
    onValue(notesRef, (snapshot) => {
       
        snapshot.forEach(note => {            
            notes.push(note.val());            
        });

        setCategorias(notes);
        console.log(notes);
        
    })
  }, [setCategorias]);


  return (
    <Container fixed>
       <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link 
              to="/panel"
              className="btn-return"
          >
             Volver al Panel Principal    
        </Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top"  />
        <h1 className="title">Tienda Virtual - Categorias </h1>
        <p className="subtitle">Acá podrás solicitar productos y/o servicios de carpinteria</p>
        <hr className="space"/>

        {
          categorias.map( categoria => (
            <CategoriaCard 
            key={categoria.Codigo}
            {...categoria} />
          ))
        
        }


        {/* <div style={{border:"solid 1px red",borderRadius:"20px", padding:"50px"}}>
            <img src={logo} alt="Logo" height={120} width={120}></img>
            <br />
            Estamos construyendo una maravillosa experiencia para ti, esperala muy pronto!!!
        </div> */}
        {/* <div className="card">
          <Grid container spacing={0}>
            <Grid item xs={3} md={2} xl={1}>
              <img src={logo} alt="Logo" height={60} width={60}></img>
            </Grid>
            <Grid item xs={9} md={10} xl={11}>
              <h3 className="title">Herramientas</h3>
              <a href="https://alamanooriente.com/FilesHeC/Memorias/MEMORIAS%20DEL%20CURSO%20PRESENCIAL%20CARPINTERIA_removed-1-28.pdf" 
              rel="noreferrer" 
              target="_blank"
              className="link card__link-internal"
              >Ver Herramientas</a>
            </Grid>            
          </Grid>          
        </div>
        <div className="card">

        <Grid container spacing={0}>
            <Grid item xs={3} md={2} xl={1}>
              <img src={logo} alt="Logo" height={60} width={60}></img>
            </Grid>
            <Grid item xs={9} md={10} xl={11}>
              <h3>Soportes de TV</h3>
              <a href="https://alamanooriente.com/FilesHeC/Memorias/MEMORIAS%20DEL%20CURSO%20PRESENCIAL%20CARPINTERIA_removed-29-58.pdf" 
              rel="noreferrer" 
              target="_blank"
              style={{textDecoration:"none",color:"red",fontWeight:"bold"}}
              >Ver Soportes de TV</a>
            </Grid>
            
          </Grid>
          
          </div>
          <div className="card">

        <Grid container spacing={2}>
            <Grid item xs={3} md={2} xl={1}>
              <img src={logo} alt="Logo" height={60} width={60}></img>
            </Grid>
            <Grid item xs={9} md={10} xl={11}>
              <h3>Repisas</h3>
              <a href="https://alamanooriente.com/FilesHeC/Memorias/MEMORIAS%20DEL%20CURSO%20PRESENCIAL%20CARPINTERIA_removed-29-58.pdf" 
              rel="noreferrer" 
              target="_blank"
              style={{textDecoration:"none",color:"red",fontWeight:"bold"}}
              >Ver Repisas</a>
            </Grid>
            
          </Grid>
          
          </div> */}
    </Container>
  )
}
