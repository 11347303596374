export const types ={
    login: '[Auth] Login',
    logout: '[Auth] Logout',

    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',
    uiStartLoading: '[UI] Start Loading',
    uiFinishLoading: '[UI] Finish Loading',

    memoriasLoad: '[Memorias] Load',

    tiendaProductosCategoriaLoad: '[Tienda] Productos Categoria Load',
    tiendaCategoriaActive: '[Tienda] Categoria Active',
    tiendaProductoActive: '[Tienda] Producto Active',
    tiendaAdicionarProducto: '[Tienda] Adicionar Producto',
    tiendaEliminarProducto: '[Tienda] Eliminar Producto',
    tiendaAdicionarValorPedido: '[Tienda] Adicionar Valor Pedido',
    tiendaReducirValorPedido: '[Tienda] Reducir Valor Pedido',
    tiendaAdicionarDatosPago: '[Tienda] Adicionar Datos Pago',
    tiendaAdicionarDatosEnvio: '[Tienda] Adicionar Datos Envio',
    tiendaLimpiarPedido: '[Tienda] Limpiar Pedido',
    tiendaLimpiarValorPedido: '[Tienda] Limpiar Valor Pedido',
    tiendaLimpiarDatosPago: '[Tienda] Limpiar Datos Pago',
    tiendaLimpiarDatosEnvio: '[Tienda] Limpiar Datos Envio',
    tiendaLimpiarCategoriaActive: '[Tienda] Limpiar Categoria Active',
    tiendaLimpiarProductoActive: '[Tienda] Limpiar Producto Active',
    tiendaLimpiarProductos: '[Tienda] Limpiar Productos',

    impresionCotizacionActive: '[Impresion] Cotizacion Active',

}