import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import validator from 'validator'
import { startRegisterWithEmailPasswordName } from '../../actions/auth';
import { setError } from '../../actions/ui';
import { useForm } from '../../hooks/useForm';


export const RegisterScreen = () => {

  const dispatch = useDispatch();
  const {msgError} =  useSelector(state => state.ui);


  const [formValues, handleInputChange] = useForm({
    // name:'Yovany',
    // email:'yovanytabares@gmail.com',
    // password:'123456',
    // passwordConfirmation:'123456'
    name:'',
    email:'',
    password:'',
    passwordConfirmation:''
  })
  
  const { name, email, password, passwordConfirmation } = formValues;

  const handleRegister = (e) => {
    e.preventDefault();

    if(isFormValid()){
      //console.log('Formulario valido');
      dispatch(startRegisterWithEmailPasswordName(email, password, name));
    }

    console.log(name, email, password, passwordConfirmation);
  }

  const isFormValid = () => {

    if(name.trim().length === 0){
      dispatch(setError('Name is required'));
      //console.log('El nombre es obligatorio');
      return false;
    } else if (!validator.isEmail(email)){
      dispatch(setError('Email is not valid'));
      //console.log('El email no es invalido');
      return false;
    }
    else if(password !== passwordConfirmation || password.length < 6){
      dispatch(setError('Password is not valid'));
      //console.log('Las contraseñas no coinciden o menos de 6 caracteres');
      return false;
    }

    dispatch(setError(null));

    return true;
  }

  return (
    <div className='auth__main'>
      <div className='auth__box-container'>
          <h3 className='auth__register' >Registro</h3>
          <form className='animate__animated animate__fadeIn animate__faster' onSubmit={handleRegister}>
            {
              msgError && 
              
                <div className='auth__alert-error'>
                    {msgError}
                </div>
              
            }
          

          <input
              type='text'
              placeholder='Nombre'
              name='name'
              className='auth__input'
              value={name}
              onChange={handleInputChange}
               />
            <input
              type='text'
              placeholder='Correo Electrónico'
              name='email'
              className='auth__input'
              value={email}
              onChange={handleInputChange}
               />
            <input
              type='password'
              placeholder='Contraseña'
              name='password'
              className='auth__input'
              value={password}
              onChange={handleInputChange}
                />
              <input
              type='password'
              placeholder='Confirmar Contraseña'
              name='passwordConfirmation'
              className='auth__input'
              value={passwordConfirmation}
              onChange={handleInputChange}
                />
            <button
              type='submit'
              className='btn btn-primary btn-block mb-5'
              >
                Registrarme
            </button>

            
              <Link 
              to="/auth/login"
              className='link'>
                ¿Ya estas registrado?
              </Link>
          </form>
      </div>
        
    </div>
  )
}
