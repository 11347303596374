import React, {useState, useRef, useEffect } from 'react'
import { Link} from 'react-router-dom'
import {Container, Grid, TextField, Button, Slide, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle}  from '@mui/material'
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import { child, update, getDatabase, onValue, ref, push, set} from "firebase/database";
import ComponentToPrint from "./ComponentToPrint";
import { BuscarClientes } from './BuscarClientes';
import { BuscarProductos } from './BuscarProductos';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { traduccionesTabla } from '../../helpers/traduccionesTabla';
import {Buffer} from 'buffer';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CrearCotizacion = () => {


  const {email} = useSelector( state => state.auth );
  let buff = new Buffer(email);
  //db
  const db = getDatabase();
  let stringToBase64 = buff.toString('base64');


  let componentRef = useRef();

  const initialValuesCliente = {'Nit':'','Nombre':''};
  const initialValuesProducto = {'itemId':'','descripcion':'','valorUnitario':'', 'unidad':''}
  //const initialValuesCotizacion = {'ClienteId':'', 'Consecutivo': '', 'CotizacionEncabezadoId':'', 'EmpresaId':'', 'Fecha':''};
  const[cotizaciones, setCotizaciones] = useState([]);
  const[openCustomer, setOpenCustomer] = useState(false);
  const[openProduct, setOpenProduct] = useState(false);
  const[producto, setProducto] = useState(initialValuesProducto);
  const[cliente, setCliente] = useState(initialValuesCliente)
  const[cantidad, setCantidad]= useState(0);
  const[cotizacion, setCotizacion] = useState("");
  const[dataCotizacionEncabezadoId, setDataCotizacionEncabezadoId] = useState("");
  const[datosDetalle, setDatosDetalle] = useState([]);
  const[cantidadItems, setCantidadItems] = useState(0);
  const[total, setTotal] = useState(0);
  const arrayFruits2 = [];
  const initialValuesEmpresa = {'Nombre': '', 'Nit': '', 'Direccion': '', 'Telefono': '', 'Celular': '', 'Correo': '', 'Ciudad': '', 'AsesorComercial':''};
  const [datosEmpresa, setDatosEmpresa] = useState(initialValuesEmpresa);

  useEffect(() => {

    const data = child(ref(db),`/CotizacionEncabezado/${stringToBase64}`);
    const cotizacionesData = [];
    onValue(data, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        cotizacionesData.push({...childSnapshot.val()});
      });      
    });

    console.log("Cotizaciones", cotizacionesData);
    setCotizaciones(cotizacionesData);

    

    setCotizacion(0);
    setTotal(0);
    setCantidadItems(0);

    //get data Empresa
    const empresaRef = child(ref(db),`/Empresas/${stringToBase64}`);
    
    onValue(empresaRef, (snapshot) => {
        snapshot.forEach(empresa => {        
          setDatosEmpresa(empresa.val());
      });
    });

  }, [db, stringToBase64]);

  const handleChangeEnvio = (event) => {
    //setDatosEmpresa({...datosEmpresa, [event.target.name]: event.target.value});
    //console.log([event.target.name]);
    //console.log(event.target.value);
    //console.log(datosEnvio);
  }

  const handleNueva = () => {
    
    

    //console.log("Cotizaciones", cotizaciones);

    /*cotizaciones.forEach(cotizacion => {
      console.log("Cotizacion Individual", cotizacion);
    } );*/

    //console.log("Cotizaciones", cotizaciones.sort((a, b) => a.consecutivo - b.consecutivo));
    
   let lastItem = cotizaciones.sort((a, b) => a.consecutivo - b.consecutivo)[cotizaciones.length - 1];
    //console.log("LastItem", lastItem);
    var intCotizacion = parseInt(lastItem.Consecutivo) + 1;
    var stringCotizacion = "";
    if(intCotizacion < 10){
      stringCotizacion = "00000" + intCotizacion;
    }else if(intCotizacion < 100){
      stringCotizacion = "0000" + intCotizacion;
    }
    else if(intCotizacion < 1000){
      stringCotizacion = "000" + intCotizacion;
    }
    else if(intCotizacion < 10000){
      stringCotizacion = "00" + intCotizacion;
    }
    else if(intCotizacion < 100000){
      stringCotizacion = "0" + intCotizacion;
    }
    else{
      stringCotizacion = "0" + intCotizacion;
    }
     setCotizacion(stringCotizacion);

    setCliente(initialValuesCliente);
    setProducto(initialValuesProducto);   
    setDatosDetalle([]);
    setCantidadItems(0);
    setTotal(0);
  }

  const handleSaveDetail = () => {
    //event.preventDefault();
    //setDatosDetalle([...datosDetalle, {'itemId':producto.itemId,'descripcion':producto.descripcion,'valorUnitario':producto.valorUnitario,'cantidad':cantidad,'valorTotal':cantidad*producto.valorUnitario}]);
    
   // setDatosDetalle([...datosDetalle, {'itemId':producto.itemId,'descripcion':producto.descripcion,'valorUnitario':producto.valorUnitario,'cantidad':cantidad,'valorTotal':cantidad*producto.valorUnitario}]);
    
   /* setArrayFruits(prevState => [...prevState, 'Apple']);
    console.log("ArrayFruits", arrayFruits);*/
    //let element = {'itemId':producto.itemId,'descripcion':producto.descripcion,'valorUnitario':producto.valorUnitario,'cantidad':cantidad,'valorTotal':cantidad*producto.valorUnitario};
   
   if(cantidad === 0){
    Swal.fire("Advertencia", "Debe ingresar una cantidad mayor a 0", "warning");
    return;
   }
   
    arrayFruits2.push(...datosDetalle, {'itemId':producto.itemId,'descripcion':producto.descripcion,'valorUnitario':producto.valorUnitario,'cantidad':cantidad, 'unidad': producto.unidad, 'valorTotal':cantidad*producto.valorUnitario});
    console.log("ArrayFruits2", arrayFruits2);
    setDatosDetalle(arrayFruits2)

    /*var datosDetalleData = {
      'itemId':producto.itemId,
      'descripcion':producto.descripcion,
      'valorUnitario':producto.valorUnitario,
      'cantidad':cantidad,
      'valorTotal':cantidad*producto.valorUnitario      
    }

    //setDatosDetalle([datosDetalleData,...datosDetalle]);

    setDatosDetalle(prevState => {
      const newState = prevState.map(item => {
        return item
      })

      console.log("PrevState", prevState);
      console.log("NewState", newState);
      //console.log("Datos Detalle", datosDetalle);
      return [...newState, datosDetalleData];
      //return newState;

    })

    //datosDetalleDataArray.push( datosDetalleData);

   

    //update setDatosDetalle with newState
    //setDatosDetalle([...datosDetalle, {'itemId':producto.itemId,'descripcion':producto.descripcion,'valorUnitario':producto.valorUnitario,'cantidad':cantidad,'valorTotal':cantidad*producto.valorUnitario}]);

    //console.log("Datos Detalle", datosDetalle);*/


    const sum = arrayFruits2.reduce((accumulator, object) => {
      return accumulator + object.valorTotal;
    }, 0);
    
    setTotal(sum);
    setCantidadItems(arrayFruits2.length);
    setProducto(initialValuesProducto);
    setCantidad(0);
    /*console.log("Suma", sum);
    console.log("Cantidad Items", datosDetalle.length);
    //console.log("Items", datosDetalle.length);
    //alert("Detalle guardado con exito");*/
  }



  const handleGuardar = (event) => {
    event.preventDefault();

    var CotizacionEncabezadoId = uuidv4();
    setDataCotizacionEncabezadoId(CotizacionEncabezadoId);
    const fecha = new Date();


    //todo: guardar cotizacion encabezado
    const newKeyEncabezado = push(child(ref(db),`/CotizacionEncabezado/${stringToBase64}`)).key;
    const newEncabezado = {
      ClienteId : cliente.ClienteId,
      Consecutivo: cotizacion,
      CotizacionEncabezadoId: CotizacionEncabezadoId,
      EmpresaId : datosEmpresa.EmpresaId,
      Fecha: fecha.toLocaleString('en-US', {timeZone: 'America/New_York'}),
      NombreEmpresa : cliente.Nombre,
      PrefijoConsecutivo : 'CT',
      ValorTotal: total,
    }


    console.log("Datos Encabezado a Guardar", newEncabezado);

    set(ref(db,`/CotizacionEncabezado/${stringToBase64}/${newKeyEncabezado}`),newEncabezado);

    //todo: guardar cotizacion detalle
    var CotizacionDetalleId = "";
    datosDetalle.forEach(item => {
      CotizacionDetalleId = uuidv4();
      const newKeyDetalle = push(child(ref(db),`/CotizacionDetalle/${stringToBase64}`)).key;
      const newDetalle = {
        Cantidad : parseFloat(item.cantidad),
        CotizacionDetalleId: CotizacionDetalleId,
        CotizacionEncabezadoId: CotizacionEncabezadoId,
        Descripcion: item.descripcion,
        ItemId: parseInt(item.itemId),
        UDM: item.unidad,
        ValorTotal: item.valorTotal,
        ValorUnitario: parseInt(item.valorUnitario)}

        console.log("Datos Detalle a Guardar", newDetalle);
      
        set(ref(db,`/CotizacionDetalle/${stringToBase64}/${newKeyDetalle}`),newDetalle);
    })




    Swal.fire("Guardado", "Cotización guardada con exito, puede proceder a imprimirla", "success");
  }

  const handleOpenCustomer = () => {
    setOpenCustomer(true);
  }

  const handleCloseCutomer = () => {
    setOpenCustomer(false);
  }

  const handleOpenProduct = () => {
    setOpenProduct(true);
  }

  const handleCloseProduct = () => {
    setOpenProduct(false);
  }

  const getProduct = (item) => {
    setProducto(item);
  }

  const getCustomer= (item) => {
    setCliente(item);
  }
 
  const handleChange = (event) => {
    setCantidad(event.target.value);
  }


  const handlePrevisualizar = () => {
    //alert(Consecutivo);
                   // localStorage.removeItem('cotizacion');
                    localStorage.setItem('cotizacion', cotizacion);
                    localStorage.setItem('valorCotizacion', total);
                    localStorage.setItem('cotizacionEncabezadoId', dataCotizacionEncabezadoId);
                    
                    

                    const db = getDatabase();
                    const condicionesComercialesRef = child(ref(db),`/CotizacionCondicionesComerciales/${stringToBase64}`);
                      onValue(condicionesComercialesRef, (snapshot) => {
                        snapshot.forEach(function(childSnapshot) {
                          localStorage.setItem('condicionesComerciales', JSON.stringify(childSnapshot.val()));
                        });
                      });

                    //get Cotizacion Observaciones
                  const observacionesCotizacionRef = child(ref(db),`/CotizacionObservaciones/${stringToBase64}`);                  
                  onValue(observacionesCotizacionRef, (snapshot) => {                    
                    snapshot.forEach(function(childSnapshot) {
                      localStorage.setItem('observaciones', JSON.stringify(childSnapshot.val()));
                    });                        
                  });

                  //get Datos Empresa
                  const empresaRef = child(ref(db),`/Empresas/${stringToBase64}`);    
                  onValue(empresaRef, (snapshot) => {
                    snapshot.forEach(empresa => {
                      localStorage.setItem('datosEmpresa', JSON.stringify(empresa.val()));
                    });
                  });

                  //datos Cliente
                  var dataCliente = {
                    'Nombre': cliente.Nombre,                    
                  }

                  localStorage.setItem('datosCliente', JSON.stringify(dataCliente));

                  //get Cotizacion Detalle
                  const detalleRef = child(ref(db),`/CotizacionDetalle/${stringToBase64}`); 
                  const detalleCotizacion = [];   
                  onValue(detalleRef, (snapshot) => {
                    snapshot.forEach(detalle => {
                      detalleCotizacion.push(detalle.val());
                    });

                    localStorage.setItem('cotizacionDetalle', JSON.stringify(detalleCotizacion));
                  });

                  //window.location.href = '/impresionCotizacion';
                  //open windows new tab
                  window.open('/impresionCotizacion', '_blank');

  }

  return (
    <div>
      <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link to={`/cotizaciones`} className="btn-return">Volver a Cotizaciones</Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top"  />
        <h1>Crear Cotización</h1>
        <p className="subtitle">Recuerde que antes de previsualizar la impresión de la cotización, antes debe haber guardado la cotización</p>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <button className="btn btn-primary" onClick={handleNueva} style={{marginRight:"10px"}}>
                  Nueva Cotización
                </button>
                <button className="btn btn-primary" onClick={handleGuardar} style={{marginRight:"10px"}}>
                  Guardar Cotización
                </button>
                <button className="btn btn-primary" onClick={handlePrevisualizar} style={{marginRight:"10px"}}>
                  Previsualizar Impresión Cotización
                </button>

 {/* <ReactToPrint 
                  trigger={() => 
                    <button className="btn btn-primary">
                      Imprimir Cotización
                    </button>}
                    onBeforePrint={() => {
                      localStorage.setItem('cotizacion', cotizacion);
                      localStorage.setItem('valorCotizacion', total);
                      localStorage.setItem('cotizacionEncabezadoId', dataCotizacionEncabezadoId);
                      
                      
  
                      const db = getDatabase();
                      const condicionesComercialesRef = child(ref(db),`/CotizacionCondicionesComerciales/${stringToBase64}`);
                        onValue(condicionesComercialesRef, (snapshot) => {
                          snapshot.forEach(function(childSnapshot) {
                            localStorage.setItem('condicionesComerciales', JSON.stringify(childSnapshot.val()));
                          });
                        });
  
                      //get Cotizacion Observaciones
                    const observacionesCotizacionRef = child(ref(db),`/CotizacionObservaciones/${stringToBase64}`);                  
                    onValue(observacionesCotizacionRef, (snapshot) => {                    
                      snapshot.forEach(function(childSnapshot) {
                        localStorage.setItem('observaciones', JSON.stringify(childSnapshot.val()));
                      });                        
                    });
  
                    //get Datos Empresa
                    const empresaRef = child(ref(db),`/Empresas/${stringToBase64}`);    
                    onValue(empresaRef, (snapshot) => {
                      snapshot.forEach(empresa => {
                        localStorage.setItem('datosEmpresa', JSON.stringify(empresa.val()));
                      });
                    });
  
                    //get Cotizacion Detalle
                    const detalleRef = child(ref(db),`/CotizacionDetalle/${stringToBase64}`); 
                    const detalleCotizacion = [];   
                    onValue(detalleRef, (snapshot) => {
                      snapshot.forEach(detalle => {
                        detalleCotizacion.push(detalle.val());
                      });
  
                      localStorage.setItem('cotizacionDetalle', JSON.stringify(detalleCotizacion));
                    });
                    }}
                  content={() => componentRef}
                  /> */}

              <TextField label="Numero" style={{marginLeft:"20px"}} variant='outlined' name="Numero" disabled onChange={handleChangeEnvio} value={cotizacion}  >

              </TextField>
            </Grid>
            
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <TextField label="Nit" variant='outlined' fullWidth name="Nit" disabled onChange={handleChangeEnvio} value={cliente.Nit}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <TextField label="Cliente" variant='outlined' fullWidth name="Cliente" disabled onChange={handleChangeEnvio} value={cliente.Nombre}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <button className="btn btn-primary" onClick={handleOpenCustomer} style={{marginTop:'16px'}}>
                  Buscar Cliente
                </button>
            </Grid>
              
              
            

            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
              <TextField label="Codigo" variant='outlined' fullWidth name="codigo" disabled onChange={handleChangeEnvio} value={producto.itemId}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={6}>
              <TextField label="Nombre" variant='outlined' fullWidth name="nombre" disabled onChange={handleChangeEnvio} value={producto.descripcion}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
              <TextField label="Precio" variant='outlined' fullWidth name="precio" disabled onChange={handleChangeEnvio} value={producto.valorUnitario}  >

              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
              <TextField label="Cantidad" variant='outlined' fullWidth name="cantidad" onChange={handleChange} value={cantidad}  >

              </TextField>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              
                <button className="btn btn-primary" onClick={handleOpenProduct} style={{marginRight:"10px"}}>
                  Buscar Items
                </button>
                <button className="btn btn-primary" onClick={handleSaveDetail}>
                  Adicionar Item
                </button>
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <h4>Detalle del Pedido</h4>
              <hr/>
              <div style={{height:'300px'}}>
              <DataGrid 
                components={{ Toolbar: GridToolbar }} 
                localeText={traduccionesTabla}
                getRowId={(r) => r.itemId}
                columns={
                  [
                    {field: 'itemId', title: 'Codigo', minWidth: 100},
                    {field: 'descripcion', title: 'Descripción', minWidth: 500},
                    {field: 'valorUnitario', title: 'Valor Unitario', minWidth: 200},
                    {field: 'cantidad', title: 'Cantidad', minWidth: 100},
                    {field: 'unidad', title: 'Unidad', minWidth: 100},
                    {field: 'valorTotal', title: 'Valor Total', minWidth: 200}
                  ]
                }
                rows={datosDetalle}>

              </DataGrid>
              </div>
            </Grid>
            

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <h4>Resumen</h4>
              <hr/>
              <p style={{float:'right'}}>
                <span style={{marginRight:'20px'}}><b>Items:</b> {cantidadItems}</span>
                <span><b>Total:</b> {total}</span>

              </p>

            </Grid>
            
            

            

          {/*  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
               

             
               <div style={{display:"none"}}><ComponentToPrint ref={(el) => (componentRef = el)} 
               customer='Yovany Tabares' /> </div> 
            </Grid> */}
            
          
        </Grid>

        <Dialog 
        open={openCustomer} 
        onClose={handleCloseCutomer}
        fullWidth={true} 
         maxWidth={'md'}
         TransitionComponent={Transition}>
          <DialogTitle>Buscar Cliente</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Buscar Cliente
            </DialogContentText>
            <BuscarClientes  getCustomer={getCustomer} closeSearch={handleCloseCutomer} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCutomer} color="primary">Cancelar</Button>
          </DialogActions>
        </Dialog>

        <Dialog 
        open={openProduct} 
        onClose={handleCloseProduct}
         fullWidth={true} 
         maxWidth={'md'}
         TransitionComponent={Transition}>
          <DialogTitle>Buscar Producto</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Buscar Producto
            </DialogContentText>
            <BuscarProductos getProduct={getProduct} closeSearch={handleCloseProduct} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseProduct} color="primary">Cancelar</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  )
}
