import { Container, Grid } from '@mui/material'
import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import logo from './../martillo_logo.jpg';

export const Panel = () => {
  return (
    <div>


    <Container fixed>

        <h2 className='title'>Selecciona la opción de tu preferencia</h2>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/memorias"
                        className="link card__link"
                    >
                        ¿Como lograr mis proyectos?    
                    </Link>
                    <p className='card__paragraph'>Acá podras acceder a material de gran utilidad</p>
                </Grid>
            </Grid>
            
            
        </div>
        
        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/videos"
                        className="link card__link"
                    >
                        Videos curso carpinteria    
                    </Link>
                    <p className='card__paragraph'>Acá podrás visualizar videos de carpinteria</p>
                </Grid>
            </Grid>
            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/libro"
                        className="link card__link"
                    >
                        Libro de carpinteria    
                    </Link>
                    <p className='card__paragraph'>Acá podrás visualizar el libro de carpinteria</p>
                </Grid>
            </Grid>
            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                    to="/categorias"
                    className="link card__link"
                    >
                        Tienda Virtual   
                    </Link>
                    <p className='card__paragraph'>Acá podrás solicitar productos y/o servicios de carpinteria</p>
                </Grid>
            </Grid>

            
        </div>

        <div className="card animate__animated animate__fadeIn">

            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/marketplace"
                        className="link card__link"
                    >
                        Marketplace  
                    </Link>
                    <p className="card__paragraph">Acá podrás comprar y/o vender productos de carpinteria</p>
                </Grid>
            </Grid>

            
        </div>

        <div className="card animate__animated animate__fadeIn">

            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/requerimientos"
                        className="link card__link"
                    >
                        Necesito un carpintero  
                    </Link>
                    <p className="card__paragraph">Acá podrás solicitar servicios de carpinteria</p>
                </Grid>
            </Grid>


            
        </div>

        <div className="card animate__animated animate__fadeIn">

            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/planos"
                        className="link card__link"
                        >
                        Planos  
                    </Link>
                    <p className="card__paragraph">Acá podrás visualizar planos de carpinteria </p>
                </Grid>
            </Grid>

            
        </div>

        <div className="card animate__animated animate__fadeIn">

            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/cotizaciones"
                        className="link card__link"
                        >
                        Cotizaciones  
                    </Link>
                    <p className="card__paragraph">Acá podrás realizar cotizaciones de carpinteria para tus clientes </p>
                </Grid>
            </Grid>

            
        </div>

    </Container>
        
    <Outlet />
    </div>
  )
}
