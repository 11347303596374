import React, {useRef} from 'react'
import ReactToPrint from 'react-to-print';
import ComponentToPrint from "./ComponentToPrint";
import { Link } from 'react-router-dom'
import {Container, Grid, TextField}  from '@mui/material'

export const ImpresionCotizacion = () => {

    let componentRef = useRef();
  return (
    <div>
          <Container fixed>
            <p style={{paddingTop:"20px",color:"White"}}>_____</p>
            <Link to={`/misCotizaciones`} className="btn-return">Volver a Mis Cotizaciones</Link>
            <p style={{paddingTop:"20px",color:"White"}}>_____</p>
            <hr className="space-top"  />
            <div style={{float:'left'}}>
                <br />

            <ReactToPrint
                    trigger={() => 
                        <button className="btn btn-primary" style={{float:"right"}}>
                        Imprimir Cotización
                        </button>}   
                        onAfterPrint={() => {
                            localStorage.removeItem('cotizacion')
                        }}                
                    content={() => componentRef}
                    />
            </div>
            
                     <br />
                     <br />
                     <br />
                {/* component to be printed */}
                <ComponentToPrint ref={(el) => (componentRef = el)}  />
        </Container>
    </div>
  )
}
