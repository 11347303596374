import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { child, getDatabase, onValue, ref } from "firebase/database";
import {Container, Grid, TextField, Button}  from '@mui/material'
import {Buffer} from 'buffer';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { CotizacionCard } from './CotizacionCard';


export const MisCotizaciones = () => {

  const {email} = useSelector( state => state.auth );
  let buff = new Buffer(email);
    let stringToBase64 = buff.toString('base64');

    const db = getDatabase();

  const initialValuesCotizacion = [{'Consecutivo':'', 'CotizacionEncabezadoId': '','NombreEmpresa':'','Fecha':'', 'ValorTotal':''}];
  const [datosCotizacion, setDatosCotizacion] = useState(initialValuesCotizacion);

  

  useEffect(() => {



    
    const cotizacionesRef = child(ref(db),`/CotizacionEncabezado/${stringToBase64}`);

    const cotizaciones = [];
    
    onValue(cotizacionesRef, (snapshot) => {
       
        snapshot.forEach(note => {            
          cotizaciones.push(note.val());            
        });

        setDatosCotizacion(cotizaciones);
        console.log(cotizaciones);
        
    })
  }, [setDatosCotizacion, email, db, stringToBase64]); 

  const handlePrevisualizar = (datosFila) => {
    //alert(Consecutivo);
                    //localStorage.removeItem('cotizacion');
                    localStorage.setItem('cotizacion', datosFila.Consecutivo);
                    localStorage.setItem('valorCotizacion', datosFila.ValorTotal);
                    localStorage.setItem('cotizacionEncabezadoId', datosFila.CotizacionEncabezadoId);
                    
                    

                    const db = getDatabase();
                    const condicionesComercialesRef = child(ref(db),`/CotizacionCondicionesComerciales/${stringToBase64}`);
                      onValue(condicionesComercialesRef, (snapshot) => {
                        snapshot.forEach(function(childSnapshot) {
                          localStorage.setItem('condicionesComerciales', JSON.stringify(childSnapshot.val()));
                        });
                      });

                    //get Cotizacion Observaciones
                  const observacionesCotizacionRef = child(ref(db),`/CotizacionObservaciones/${stringToBase64}`);                  
                  onValue(observacionesCotizacionRef, (snapshot) => {                    
                    snapshot.forEach(function(childSnapshot) {
                      localStorage.setItem('observaciones', JSON.stringify(childSnapshot.val()));
                    });                        
                  });

                  //get Datos Empresa
                  const empresaRef = child(ref(db),`/Empresas/${stringToBase64}`);    
                  onValue(empresaRef, (snapshot) => {
                    snapshot.forEach(empresa => {
                      localStorage.setItem('datosEmpresa', JSON.stringify(empresa.val()));
                    });
                  });

                  //datos Cliente
                  var dataCliente = {
                    'Nombre': datosFila.NombreEmpresa,                    
                  }

                  localStorage.setItem('datosCliente', JSON.stringify(dataCliente));

                  //get Datos Cliente
                  /*const clientes = [];
                  const clienteRef = child(ref(db),`/Clientes/${stringToBase64}`);  
                  console.log("Cliente Ref", clienteRef)  
                  onValue(clienteRef, (snapshot) => {
                    snapshot.forEach(cliente => {
                      clientes.push(cliente.val());
                      clientes.push("1");                      
                    });
                  });

                  console.log("Clientes", clientes);
                  var dataCliente = clientes.filter(cliente => cliente.ClienteId === datosFila.ClienteId);
                  console.log("datos Cliente", dataCliente);
                  if(dataCliente.length > 0){
                    localStorage.setItem('datosCliente', JSON.stringify(dataCliente.val()));
                  }*/

                  //get Cotizacion Detalle
                  const detalleRef = child(ref(db),`/CotizacionDetalle/${stringToBase64}`); 
                  const detalleCotizacion = [];   
                  onValue(detalleRef, (snapshot) => {
                    snapshot.forEach(detalle => {
                      detalleCotizacion.push(detalle.val());
                    });

                    localStorage.setItem('cotizacionDetalle', JSON.stringify(detalleCotizacion));
                  });

                  //window.location.href = '/impresionCotizacion';

                  window.open('/impresionCotizacion', '_blank');

  }

  return (
    <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link to={`/cotizaciones`} className="btn-return">Volver a Cotizaciones</Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top"  />
        <h1>Ver mis cotizaciones</h1>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>

        <div style={{height:'650px'}}>
        <DataGrid 
                
                
                components={{ Toolbar: GridToolbar }} 
                getRowId={(r) => r.CotizacionEncabezadoId}
                columns={
                    [
                        {field: 'Consecutivo', title: 'Consecutivo', minWidth:200},
                        {field: 'NombreEmpresa', title: 'NombreEmpresa', minWidth:200},
                        {field: 'Fecha', title: 'Fecha', minWidth:120},
                        {field: 'ValorTotal', title: 'ValorTotal', minWidth:200},
                        {
                            field: 'Previsualizar',
                            headerName: 'Previsualizar',
                            renderCell: (params) => {
                                return (
                                    <div>
                                        <Button onClick={() => {
                                            //console.log(params.row);
                                            //alert(params.row.descripcion);
                                            //closeSearch();
                                            //getCustomer(params.row);
                                            handlePrevisualizar(params.row);
                                        } }>Previsualizar</Button>
                                    </div>
                                );
                            }
                        }
                    ]
                }
                rows={datosCotizacion}>

            </DataGrid>
    </div>

         
         {/*
          datosCotizacion.map( note => (
            <CotizacionCard 
            key={note.CotizacionEncabezadoId}
            {...note} />
          ))
        
          */} 
       
      </Container>
  )
}
