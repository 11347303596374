import React, { useEffect, useRef } from 'react'

export const EpaycoButton = ({visible, total, tituloCompra, detalleCompra, email, NombrePago, DireccionPago, TipoDocumentoPago, CelularPago, NumeroDocumentoPago }) => {
  
    // const buttonContainerRef = useRef(); // Creating ref element to assign as a form element attribute

    // useEffect(() => {
    //     scriptLoader();
    //     return () => {
    //         scriptUnloader(); // Remove the script element on unmount
    //     };
    // }, []);  

    // const scriptLoader = () => {
    //     const script = document.createElement("script");
    //     script.src = "https://checkout.epayco.co/checkout.js";
    //     script.id = "checkout-button";
    //     script.async = true;
    //     document.body.appendChild(script);
    //     window.addEventListener('load', scriptMover) // Runs when all the assets loaded completely
    // };

    // const scriptUnloader = () => {
    //     document.body.removeChild(document.querySelector("#checkout-button"));
    // }

    // const scriptMover = () => {
    //     const button = document.querySelector("#checkout-button"); // Gets the button
    //     buttonContainerRef.current.appendChild(button) // Appends the button to the ref element, in this case form element
    // };

    //http://localhost:3000/respuesta
    //https://hagaloencasa.co/respuesta
  
    return (
    visible && <div style={{'border':'1px solid red','padding':'20px','border-radius':'15px'}}>
        
        Zona de pagos - Ahora puedes proceder con tu pago para finalizar tu pedido
        <br/>
        <br/>
        <form>
          <script
            src="https://checkout.epayco.co/checkout.js"
            className="epayco-button"
            data-epayco-key="fcdf7e86daf1137e62a16e8010d3309f"
            data-epayco-amount={total}
            data-epayco-name={tituloCompra}
            data-epayco-description={detalleCompra}
            data-epayco-currency="cop"
            data-epayco-country="co"
            data-epayco-test="false"
            data-epayco-external="false"
            data-epayco-response="https://hagaloencasa.co/respuesta"
            data-epayco-confirmation="https://ejemplo.com/confirmacion"
            data-epayco-methodconfirmation="post"
            data-epayco-email-billing={email}
            data-epayco-name-billing={NombrePago}
            data-epayco-address-billing={DireccionPago}
            data-epayco-type-doc-billing={TipoDocumentoPago}
            data-epayco-mobilephone-billing	={CelularPago}
            data-epayco-number-doc-billing={NumeroDocumentoPago}
          ></script>
        </form>
    </div>
  )
}
