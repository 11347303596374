import { Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from './../../martillo_logo.jpg';

export const Marketplace = () => {
  return (
    <div>
        <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link 
              to="/panel"
              className="btn-return"
          >
             Volver  
        </Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top" />
        
        <h1 className="title">Marketplace</h1>
        <p className="subtitle">Compra y vende productos de relacionados con carpinteria</p>
        <hr className="space"/>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/marketplaceProductos/mobiliarios"
                        className="link card__link"
                    >
                       Mobiliarios  
                    </Link>
                    <p className='card__paragraph'>Encuentra Mobiliarios Disponibles</p>
                    <p style={{marginTop:"20px"}}><b style={{textTransform:"lowercase"}}>Articulos disponibles actualmente:</b> 0</p>
                </Grid>
            </Grid>            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/marketplaceProductos/mobiliariosSaldos"
                        className="link card__link"
                    >
                       Mobiliarios Saldos
                    </Link>
                    <p className='card__paragraph'>Encuentra Saldos de Mobiliarios Disponible</p>
                    <p style={{marginTop:"20px"}}><b style={{textTransform:"lowercase"}}>Articulos disponibles actualmente:</b> 0</p>
                </Grid>
            </Grid>            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/marketplaceProductos/herramientaNueva"
                        className="link card__link"
                    >
                       Herramienta Nueva
                    </Link>
                    <p className='card__paragraph'>Encuentra Herramienta Nueva Disponible</p>
                    <p style={{marginTop:"20px"}}><b style={{textTransform:"lowercase"}}>Articulos disponibles actualmente:</b> 0</p>
                </Grid>
            </Grid>            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/marketplaceProductos/herramientaUsada"
                        className="link card__link"
                    >
                       Herramienta Usada
                    </Link>
                    <p className='card__paragraph'>Encuentra Herramienta Usada Disponible</p>
                    <p style={{marginTop:"20px"}}><b style={{textTransform:"lowercase"}}>Articulos disponibles actualmente:</b> 0</p>
                </Grid>
            </Grid>            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/marketplaceProductos/serviciosMantenimiento"
                        className="link card__link"
                    >
                       Servicios Mantenimiento
                    </Link>
                    <p className='card__paragraph'>Encuentra Servicio de Mantenimiento Disponible</p>
                    <p style={{marginTop:"20px"}}><b style={{textTransform:"lowercase"}}>Articulos disponibles actualmente:</b> 0</p>
                </Grid>
            </Grid>            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/marketplaceProductos/serviciosManoObra"
                        className="link card__link"
                    >
                       Servicios Mano de Obra
                    </Link>
                    <p className='card__paragraph'>Encuentra Servicio de Mano de Obra Disponible</p>
                    <p style={{marginTop:"20px"}}><b style={{textTransform:"lowercase"}}>Articulos disponibles actualmente:</b> 0</p>
                </Grid>
            </Grid>            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/marketplaceProductos/tapiceria"
                        className="link card__link"
                    >
                       Tapiceria
                    </Link>
                    <p className='card__paragraph'>Encuentra Tapiceria Disponible</p>
                    <p style={{marginTop:"20px"}}><b style={{textTransform:"lowercase"}}>Articulos disponibles actualmente:</b> 0</p>
                </Grid>
            </Grid>            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/marketplaceProductos/estructurasMetalicas"
                        className="link card__link"
                    >
                       Estructuras Metalicas
                    </Link>
                    <p className='card__paragraph'>Encuentra Estructuras Metalicas Disponibles</p>
                    <p style={{marginTop:"20px"}}><b style={{textTransform:"lowercase"}}>Articulos disponibles actualmente:</b> 0</p>
                </Grid>
            </Grid>            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/marketplaceProductos/pintoresMadera"
                        className="link card__link"
                    >
                       Pintores de Madera
                    </Link>
                    <p className='card__paragraph'>Encuentra Pintores de Madera Disponibles</p>
                    <p style={{marginTop:"20px"}}><b style={{textTransform:"lowercase"}}>Articulos disponibles actualmente:</b> 0</p>
                </Grid>
            </Grid>            
        </div>

        </Container>
    </div>
  )
}
