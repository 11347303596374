import React from 'react'
import { Grid } from '@mui/material'
import logo from './../../martillo_logo.jpg';

export const PlanoCard = ({Codigo, Descripcion, UrlDocumento}) => {
  return (
    <div className="card">
    <Grid container spacing={0}>
      <Grid item xs={3} md={2} xl={1}>
        <img src={logo} alt="Logo" height={60} width={60}></img>
      </Grid>
      <Grid item xs={9} md={10} xl={11}>
        <h3 className="title">{Descripcion}</h3>
        <a href={UrlDocumento} 
        rel="noreferrer" 
        target="_blank"
        className="link card__link-internal"
        >Ver documento</a>
      </Grid>
      
    </Grid>
    
    
  </div>
  )
}
