import { Container} from '@mui/material'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { child, getDatabase, onValue, ref, set } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { VideoCard } from './VideoCard';

export const Videos = () => {


  const {email} = useSelector( state => state.auth );

  const initialValues = [{"CategoriaCodigo":"","Codigo":"","Descripcion":"","Gratuito":"","UrlVideo":""}];

  const [notas, setNotas] = useState(initialValues);
  const[carpinteriaEn1Minuto, setCarpinteriaEn1Minuto] = useState(initialValues);
  const[materiales, setMateriales] = useState(initialValues);
  const[cocinas, setCocinas] = useState(initialValues);
  const[herrajes, setHerrajes] = useState(initialValues);
  const[puertas, setPuertas] = useState(initialValues);
  const[closet, setCloset] = useState(initialValues);
  const[despieceOptimizacion, setDespieceOptimizacion] = useState(initialValues);
  const[centroTV, setCentroTV] = useState(initialValues);
  const[herramientas, setHerramientas] = useState(initialValues);
  const[sketchupII, setSketchupII] = useState(initialValues);
  const[sketchup, setSketchup] = useState(initialValues);
  const[sketchupIII, setSketchupIII] = useState(initialValues);
  const[clasesGratuitas2023, setClasesGratuitas2023] = useState(initialValues);

  const[value,setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const db = getDatabase();
    const videosRef = child(ref(db),`Videos`);
    const permisosVideosRef = child(ref(db),`Permisos/Videos`);
    const allVideos = [];
    const allPermisosVideos = [];
    let userAllowVideos = false;

    onValue(permisosVideosRef, (snapshot) => {      

      snapshot.forEach(permiso => {            
        allPermisosVideos.push(permiso.val());            
      });

        userAllowVideos = allPermisosVideos.find(permiso => permiso.Email === email);
        
        if(userAllowVideos){       
          console.log('Usuario autorizado');
        }
        else{
          console.log('Usuario no autorizado');
        }
        
    })
    

    var dataCarpinteriaEn1Minuto = [];
    var dataMateriales = [];
    var dataCocinas = [];
    var dataHerrajes = [];
    var dataPuertas = [];
    var dataCloset = [];
    var dataDespieceOptimizacion = [];
    var dataCentroTV =  [];
    var dataHerramientas = [];
    var dataSketchupII = [];
    var dataSketchup = [];
    var dataSketchupIII = [];
    var dataClasesGratuitas2023 = [];

    onValue(videosRef, (snapshot) => {      

        snapshot.forEach(video => {            
          allVideos.push(video.val());            
        });

        

        if(userAllowVideos){
          //setNotas(allVideos)


         dataCarpinteriaEn1Minuto = allVideos.filter(video => video.CategoriaCodigo === 'a75d6ef6-4e10-4b5c-9fec-5104756d28fb');
         dataMateriales = allVideos.filter(video => video.CategoriaCodigo === '854d757a-7ca2-4959-af9b-9d460bd1e0c5');
         dataCocinas = allVideos.filter(video => video.CategoriaCodigo === '97c78898-10f8-40a2-839e-e5077c24ae3e');
         dataHerrajes = allVideos.filter(video => video.CategoriaCodigo === '1d35279f-54a5-4bf6-b30e-fcad7fd3f0a0');
         dataPuertas = allVideos.filter(video => video.CategoriaCodigo === 'fa27173c-9628-46dc-951d-fd905baee624');
         dataCloset = allVideos.filter(video => video.CategoriaCodigo === '8fe4695e-5e1d-4ffd-9ae7-d1b6b37d5b6c');
         dataDespieceOptimizacion = allVideos.filter(video => video.CategoriaCodigo === 'b9a547a4-f87c-48be-a2e5-4608afa8eb7a');
         dataCentroTV = allVideos.filter(video => video.CategoriaCodigo === '81862951-6240-4a49-a85b-bfdc70055c91');
         dataHerramientas = allVideos.filter(video => video.CategoriaCodigo === 'b7307e6b-78e3-4bfe-83c1-86bb05a19b98');
         dataSketchupII = allVideos.filter(video => video.CategoriaCodigo === '81c701a4-5c77-4c33-a019-76de80f275f7');
         dataSketchup = allVideos.filter(video => video.CategoriaCodigo === 'e0e754ef-3341-4d55-b7ef-52a5e8ea399c');
         dataSketchupIII = allVideos.filter(video => video.CategoriaCodigo === '5189de70-33b8-4155-ac71-c218a71102bd');
         dataClasesGratuitas2023 = allVideos.filter(video => video.CategoriaCodigo === '109b9223-15f3-4e2e-a1b3-634ab830c881');

          setCarpinteriaEn1Minuto(dataCarpinteriaEn1Minuto)
          setMateriales(dataMateriales)
          setCocinas(dataCocinas)
          setHerrajes(dataHerrajes)
          setPuertas(dataPuertas)
          setCloset(dataCloset)
          setDespieceOptimizacion(dataDespieceOptimizacion)
          setCentroTV(dataCentroTV)
          setHerramientas(dataHerramientas)
          setSketchupII(dataSketchupII)
          setSketchup(dataSketchup)
          setSketchupIII(dataSketchupIII)
          setClasesGratuitas2023(dataClasesGratuitas2023)
        }
        else{
          //const freeVideos = allVideos.filter(note => note.Gratuito === true);
          //setNotas(freeVideos)

         dataCarpinteriaEn1Minuto = allVideos.filter(video => video.CategoriaCodigo === 'a75d6ef6-4e10-4b5c-9fec-5104756d28fb' && video.Gratuito === true);
         dataMateriales = allVideos.filter(video => video.CategoriaCodigo === '854d757a-7ca2-4959-af9b-9d460bd1e0c5' && video.Gratuito === true);
         dataCocinas = allVideos.filter(video => video.CategoriaCodigo === '97c78898-10f8-40a2-839e-e5077c24ae3e' && video.Gratuito === true);
         dataHerrajes = allVideos.filter(video => video.CategoriaCodigo === '1d35279f-54a5-4bf6-b30e-fcad7fd3f0a0' && video.Gratuito === true);
         dataPuertas = allVideos.filter(video => video.CategoriaCodigo === 'fa27173c-9628-46dc-951d-fd905baee624' && video.Gratuito === true);
         dataCloset = allVideos.filter(video => video.CategoriaCodigo === '8fe4695e-5e1d-4ffd-9ae7-d1b6b37d5b6c' && video.Gratuito === true);
         dataDespieceOptimizacion = allVideos.filter(video => video.CategoriaCodigo === 'b9a547a4-f87c-48be-a2e5-4608afa8eb7a' && video.Gratuito === true);
         dataCentroTV = allVideos.filter(video => video.CategoriaCodigo === '81862951-6240-4a49-a85b-bfdc70055c91' && video.Gratuito === true);
         dataHerramientas = allVideos.filter(video => video.CategoriaCodigo === 'b7307e6b-78e3-4bfe-83c1-86bb05a19b98' && video.Gratuito === true);
         dataSketchupII = allVideos.filter(video => video.CategoriaCodigo === '81c701a4-5c77-4c33-a019-76de80f275f7' && video.Gratuito === true);
         dataSketchup = allVideos.filter(video => video.CategoriaCodigo === 'e0e754ef-3341-4d55-b7ef-52a5e8ea399c' && video.Gratuito === true);
         dataSketchupIII = allVideos.filter(video => video.CategoriaCodigo === '5189de70-33b8-4155-ac71-c218a71102bd' && video.Gratuito === true);
         dataClasesGratuitas2023 = allVideos.filter(video => video.CategoriaCodigo === '109b9223-15f3-4e2e-a1b3-634ab830c881' && video.Gratuito === true);

          setCarpinteriaEn1Minuto(dataCarpinteriaEn1Minuto)
          setMateriales(dataMateriales)
          setCocinas(dataCocinas)
          setHerrajes(dataHerrajes)
          setPuertas(dataPuertas)
          setCloset(dataCloset)
          setDespieceOptimizacion(dataDespieceOptimizacion)
          setCentroTV(dataCentroTV)
          setHerramientas(dataHerramientas)
          setSketchupII(dataSketchupII)
          setSketchup(dataSketchup)
          setSketchupIII(dataSketchupIII)
          setClasesGratuitas2023(dataClasesGratuitas2023)
        }
        
    })
  }, [setNotas, email]);


  return (
    <div>
        <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link 
              to="/panel"
              className="btn-return"
          >
             Volver  
        </Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top" />
        
        <h1 className="title">Videos</h1>
        <p className="subtitle">Te presentamos videos de carpinteria muy utiles para tus procesos</p>
        <hr className="space"/>

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList variant="scrollable" scrollButtons="auto" onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Carpinteria en 1 minuto" value="1" />
                <Tab label="Materiales" value="2" />
                <Tab label="Cocinas" value="3" />
                <Tab label="Herrajes" value="4" />
                <Tab label="Puertas" value="5" />
                <Tab label="Closet" value="6" />
                <Tab label="Despiece y Optimización" value="7" />
                <Tab label="Centro de TV" value="8" />
                <Tab label="Herramientas" value="9" />
                <Tab label="Sketchup II - Cocinas" value="10" />
                <Tab label="Sketchup" value="11" />
                <Tab label="Skectchup III" value="12" />
                <Tab label="Clases Gratuitas 2023" value="13" />
              </TabList>
            </Box>
            <TabPanel value="1">
              
            {
              carpinteriaEn1Minuto.length > 0 ? (
                           
                carpinteriaEn1Minuto.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                ))
              ) : (
                <p>No hay videos disponibles</p>
              )
            
            }
            </TabPanel>
            <TabPanel value="2">
            {
              materiales.length > 0 ? (
                materiales.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                ))
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )
            
            }
            </TabPanel>
            <TabPanel value="3">
            {
              cocinas.length > 0 ? (
                cocinas.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                ))
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )
            
            }
            </TabPanel>
            <TabPanel value="4">
            {
              herrajes.length > 0 ? (
                herrajes.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                ))
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )
            
            }
            </TabPanel>
            <TabPanel value="5">
            {
              puertas.length > 0 ? (
                puertas.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                ))
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )
            
            }
            </TabPanel>
            <TabPanel value="6">
            {
              closet.length > 0 ? (
                closet.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                ))
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )
            
            }
            </TabPanel>
            <TabPanel value="7">
            {
              despieceOptimizacion.length > 0 ? (
                despieceOptimizacion.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                ))
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )
              
            }
            </TabPanel>
            <TabPanel value="8">
            {
              centroTV.length > 0 ? (
                centroTV.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                ))
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )
            
            }
            </TabPanel>
            <TabPanel value="9">
            {
              herramientas.length > 0 ? (
                herramientas.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                ))
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )
            
            }
            </TabPanel>
            <TabPanel value="10">
            {
              sketchupII.length > 0 ? (
                sketchupII.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                )) 
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )
            }
            </TabPanel>
            <TabPanel value="11">
            {
              sketchup.length > 0 ? (
                sketchup.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                )) 
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )     

            }
            </TabPanel>
            <TabPanel value="12">
            {
              sketchupIII.length > 0 ? (
                sketchupIII.map( note => (
                  <VideoCard 
                  key={note.Codigo}
                  {...note} />
                ))    
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )
            }
            </TabPanel>
            <TabPanel value="13">
            {
              clasesGratuitas2023.length > 0 ? (
                clasesGratuitas2023.map( note => (
                  <VideoCard
                  key={note.Codigo}
                  {...note} />
                ))
              ) : (
                <p>No hay videos disponibles en tu suscripción gratuita, para ver los videos debes adquirir el acceso</p>
              )
            }
            </TabPanel>
          </TabContext>
        </Box>



        {/*{
          notas.map( note => (
            <VideoCard 
            key={note.Codigo}
            {...note} />
          ))
        
        } */}



        {/* <div style={{border:"solid 1px red",borderRadius:"20px", padding:"50px"}}>
            <img src={logo} alt="Logo" height={120} width={120}></img>
            <br />
            Estamos cosntruyendo una maravillosa experiencia para ti, esperala muy pronto!!!
        </div> */}

        </Container>
    </div>
  )
}
