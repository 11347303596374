import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React from 'react'
import Swal from 'sweetalert2';

export const PagarTC = () => {

  const cuotas = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36'];

  const handlePagar = () => {
    Swal.fire('Ir a pagar');
    // alert('Ir a pagar');
  }

  const handleCancelar = () => {
    Swal.fire('Cancelar')
    //alert('Cancelar');
  }

  return (
    <div>
      <Container>
        <Box sx={{ paddingTop: '20px' }} />
        <Typography variant="h5" component="h6">
          Pago por medio de Tarjeta de Crédito
        </Typography>
        <Box sx={{ paddingTop: '20px' }} />
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField label="Valor" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tipo Documento</InputLabel>
              <Select label="banco" fullWidth>
              <MenuItem value="">
                <em>Seleccione tipo documento</em>
              </MenuItem>
              <MenuItem value={'CC'}>Cédula de Ciudadania</MenuItem>
              <MenuItem value={'NI'}>Nit</MenuItem>
            </Select>
            </FormControl>
            
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Número Documento" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Nombres" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Apellidos" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Correo Electrónico" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Celular" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Télefono" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Número Tarjeta" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Año expiración" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Mes Expiración" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Código Verificación" variant='outlined' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Cuotas</InputLabel>
              <Select label="banco" fullWidth>
              <MenuItem value="">
                <em>Seleccione número de Cuotas</em>
              </MenuItem>
              {
                cuotas.map(cuota => (
                  <MenuItem key={cuota} value={cuota}>{cuota}</MenuItem>
                ))
              }
            </Select>
            </FormControl>
            
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
            <Button 
              variant='contained' 
              fullWidth
              onClick={handlePagar}>Pagar</Button>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
            <Button 
              variant='contained'
              fullWidth
              onClick={handleCancelar}>Cancelar</Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
