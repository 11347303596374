import {  Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from './../../martillo_logo.jpg';



export const Cotizaciones = () => {

 
  

  return (
    <div>
        <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link 
              to="/panel"
              className="btn-return"
          >
             Volver  
        </Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top" />
        
        <h1 className="title">Cotizaciones</h1>
        <p className="subtitle">Realiza de forma agil y precisa tus cotizaciones de carpinteria</p>
        {/*<div style={{backgroundColor:"lightblue", padding:"20px"}}>
            <p>Funcionalidad en proceso de Pruebas - Favor no usar para procesos reales</p>
  </div> */}
        <hr className="space"/>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/datosEmpresa"
                        className="link card__link"
                    >
                       Administrar Datos Empresa    
                    </Link>
                    <p className='card__paragraph'>Acá podras acceder a crear o modificar los daros de tu empresa</p>
                </Grid>
            </Grid>            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/datosClientes"
                        className="link card__link"
                    >
                       Administrar Datos Clientes    
                    </Link>
                    <p className='card__paragraph'>Acá podras acceder a crear o modificar los daros de tus clientes</p>
                </Grid>
            </Grid>            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/configurarCotizacion"
                        className="link card__link"
                    >
                       Configurar Cotización   
                    </Link>
                    <p className='card__paragraph'>Acá podras acceder a configurar los items de la cotización </p>
                </Grid>
            </Grid>            
        </div>
        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/crearCotizacion"
                        className="link card__link"
                    >
                       Crear Cotización 
                    </Link>
                    <p className='card__paragraph'>Acá podras acceder a crear tu cotización</p>
                </Grid>
            </Grid>            
        </div>
        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <Link 
                        to="/misCotizaciones"
                        className="link card__link"
                    >
                       Ver mis cotizaciones 
                    </Link>
                    <p className='card__paragraph'>Acá podras acceder a ver tus cotizaciones</p>
                </Grid>
            </Grid>            
        </div>

        <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <a href="https://duratex.com.co/optimizadordecorte/" target="_blank" rel="noreferrer" className="link card__link">Optimizador de corte</a>
                    <p className='card__paragraph'>Acá podras acceder al optimizador de cortes</p>
                </Grid>
            </Grid>            
        </div>
        
        

        </Container>
    </div>
  )
}
