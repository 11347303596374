import { fontFamily } from '@mui/system';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'



    export default class ComponentToPrint  extends React.Component {
      

        render() {


            

          var cotizacion = localStorage.getItem('cotizacion') ? localStorage.getItem('cotizacion') : "0";
          //alert("Datos enviados a impresión " + cotizacion);
          console.log("Datos enviados a impresión " + cotizacion);
          var cotizacionEncabezadoId = localStorage.getItem('cotizacionEncabezadoId') ? localStorage.getItem('cotizacionEncabezadoId') : "0";
          var valorCotizacion = localStorage.getItem('valorCotizacion') ? localStorage.getItem('valorCotizacion') : "0";
          var condicionesComerciales = localStorage.getItem('condicionesComerciales') ? JSON.parse(localStorage.getItem('condicionesComerciales')) : {};
          var datosEmpresa = localStorage.getItem('datosEmpresa') ? JSON.parse(localStorage.getItem('datosEmpresa')) : {};
          var datosCliente = localStorage.getItem('datosCliente') ? JSON.parse(localStorage.getItem('datosCliente')) : {};
          //console.log("datosEmpresa", datosEmpresa);
          var detalle = localStorage.getItem('cotizacionDetalle') ? JSON.parse(localStorage.getItem('cotizacionDetalle')) : {};
          
          var detalleCotizacion = [];
          if(detalle.length > 0){
             detalleCotizacion = detalle.filter(item => item.CotizacionEncabezadoId === cotizacionEncabezadoId);
          }
          //console.log("detalle Cot", detalleCotizacion);
          var observaciones = localStorage.getItem('observaciones') ? JSON.parse(localStorage.getItem('observaciones')) : {};
          //console.log("Condiciones Comerciales", condicionesComerciales);
          const nombre = "NombreCliente"
          const direccion = "DireccionCliente"

          
          


            return (
                <div style={{marginLeft:"1.2em"}}>
                    <br />
        <h2 style={{color: "black"}}>Cotización Servicio de Carpintería - {cotizacion}</h2>
        <br />
        <div style={{display:'flex', justifyContent:'space-between'}}>

        
        <div style={{border:"1px solid white"}}>
        <h2>Datos Empresa</h2>
        <ul style={{listStyleType:"none", marginLeft:"-40px"}}>
            <li><b>Nit: </b>{datosEmpresa.Nit}</li>
            <li><b>Nombre: </b> {datosEmpresa.Nombre}</li>
            <li><b>Dirección: </b> {datosEmpresa.Direccion}</li>
            <li><b>Teléfono: </b>{datosEmpresa.Telefono}</li>
            <li><b>Celular: </b> {datosEmpresa.Celular}</li>
            <li><b>Email: </b> {datosEmpresa.Correo}</li>
            <li><b>Ciudad:</b> {datosEmpresa.Ciudad}</li>
        </ul>
        </div>


        <div style={{border:"1px solid white", marginRight:'20px'}}>
        <h2>Datos del Cliente</h2>
        <ul style={{listStyleType:"none", marginLeft:"-40px"}}>
            <li><b>Nombre: </b> {datosCliente.Nombre}</li>
        </ul>
        </div>
        
        </div>
        
        <hr />

        <table style={{backgroundColor:"White", marginTop:"50px"}}>
          <thead style={{paddingTop:'12px', backgroundColor:'red', color:"white"}}>
            <th>PROYECTO / PRODUCTO</th>
            <th>UDM</th>
            <th>CANT</th>            
            <th>VR. UNITARIO</th>
            <th>TOTAL</th>
          </thead>
          <tbody>
          {
              detalleCotizacion.map(item => {
                return (
                <tr >
                    <td style={{border:"1px solid black"}}>{item.Descripcion}</td>
                    <td style={{border:"1px solid black"}}>{item.UDM}</td>
                    <td style={{border:"1px solid black"}}>{item.Cantidad}</td>                    
                    <td style={{border:"1px solid black"}}>{item.ValorUnitario.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td style={{border:"1px solid black"}}>{item.ValorTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
                )

              })
            }
          </tbody>
        </table>

        <br/>

        <b>Total: </b><p>{valorCotizacion.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        <br/>
        <hr/>
        <p><b>Observaciones Generales de la Cotización</b></p>
       
        <p>{observaciones.Descripcion}</p>
        <br/>
        <hr/>
        <p><b>Condiciones Comerciales</b></p>
        <p><b>Entrega</b> {condicionesComerciales.Entrega}</p>
        <p><b>Anticipo</b> {condicionesComerciales.PorcentajeAnticipo}</p>
        <p><b>Vigencia de la Oferta</b>{condicionesComerciales.Vigencia}</p>
        <p><b>Descuento Pago Efectivo</b>{condicionesComerciales.PorcentajeDescuentoEfectivo}</p>
        <p><b>Otros Descuentos</b>{condicionesComerciales.PorcentajeOtrosDescuentos}</p>
        <br/>
        <hr/>
        <p><b>Asesor Comercial</b></p>
        <p>{datosEmpresa.AsesorComercial}</p>

      </div>
            )
        }
    }

