import { Container } from '@mui/material'
import { child, getDatabase, onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { LibroCard } from './LibroCard';

export const Libro = () => {

  const {email} = useSelector( state => state.auth );

  const initialValues = [{"Codigo":"","Descripcion":"","UrlDocumento":""}];

  const [notas, setNotas] = useState(initialValues);

  const[allowLibro, setAllowLibro] = useState(false);
  

  useEffect(() => {
    const db = getDatabase();
    const libroRef = child(ref(db),`Libros`);
    const permisosLibroRef = child(ref(db),`Permisos/Libro`);
    const allLibros = [];
    const allPermisosLibros = [];
    let userAllowLibros = false;
    

    onValue(permisosLibroRef, (snapshot) => {      

      snapshot.forEach(permiso => {            
        allPermisosLibros.push(permiso.val());            
      });

        userAllowLibros = allPermisosLibros.find(permiso => permiso.Email === email);
        
        if(userAllowLibros){       
          console.log('Usuario autorizado');
          //console.log(userAllowLibros);
          setAllowLibro(true);
        }
        else{
          console.log('Usuario no autorizado');
          //console.log(userAllowLibros);
          setAllowLibro(false);
        }

        console.log(allowLibro);
        
    })
    
    onValue(libroRef, (snapshot) => {      

        snapshot.forEach(video => {            
          allLibros.push(video.val());            
        });

        if(userAllowLibros){
          setNotas(allLibros)
          console.log(allLibros);
        }
        else{
          // const freeVideos = allLibros.filter(note => note.Gratuito === true);
          // setNotas(freeVideos)
        }
        
    })
  }, [setNotas, email]);


  return (
    <div>
        <Container fixed>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <Link 
              to="/panel"
              className="btn-return"
          >
             Volver  
        </Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top" />
        
        <h1 className="title">Libro</h1>
        <p className="subtitle">Descarga el libro de carpinteria para no carpinteros</p>
        <hr className="space"/>

        

        {
         
          (allowLibro) ?
          (
            notas.map( note => (
            <LibroCard 
            key={note.Codigo}
            {...note} />
          )))
          :
          (<h1>No tienes permisos para ver este contenido, puedes realizar la compra de este permiso desde la app móvil</h1>)
        
        }
        

        {/* <div style={{border:"solid 1px red",borderRadius:"20px", padding:"50px"}}>
            <img src={logo} alt="Logo" height={120} width={120}></img>
            <br />
            Estamos cosntruyendo una maravillosa experiencia para ti, esperala muy pronto!!!
        </div> */}

        </Container>
    </div>
  )
}
