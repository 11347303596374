export const traduccionesTabla = {
    columnMenuUnsort: "No clasificado",
    columnMenuSortAsc: "Clasfiicar Ascendente",
    columnMenuSortDesc: "Clasificar Descendente",
    columnMenuFilter: "Filtro",
    columnMenuHideColumn: "Ocultar",
    columnMenuShowColumns: "Montrar",
     // Density selector toolbar button text
    toolbarDensity: 'Densidad',
    toolbarDensityLabel: 'Densidad',
    toolbarDensityCompact: 'Compacto',
    toolbarDensityStandard: 'Estandar',
    toolbarDensityComfortable: 'Confortable',

     // Columns selector toolbar button text
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Seleccionar Columnas',

    // Filters toolbar button text
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Mostrar Filtros',
    toolbarFiltersTooltipHide: 'Ocultar Filtros',
    toolbarFiltersTooltipShow: 'Mostrar Filtros',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtros activos` : `${count} filtros activos`,
    
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Buscar…',
    toolbarQuickFilterLabel: 'Buscar',
    toolbarQuickFilterDeleteIconLabel: 'Limpiar',

     // Export selector toolbar button text
    toolbarExport: 'Exportar',
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'Descargar como CSV',
    toolbarExportPrint: 'Imprimir',
    toolbarExportExcel: 'Descargar como Excel',

      // Columns panel text
    columnsPanelTextFieldLabel: 'Buscar columna',
    columnsPanelTextFieldPlaceholder: 'Titulo de Columna',
    columnsPanelDragIconLabel: 'Reordenar Columna',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',

     // Filter panel text
    filterPanelAddFilter: 'Agregar filtro',
    filterPanelDeleteIconLabel: 'Eliminar',
    filterPanelLinkOperator: 'Operador Logico',
    filterPanelOperators: 'Operador', // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor del Filtro',

      // Filter operators text
    filterOperatorContains: 'Contiene',
    filterOperatorEquals: 'Igual a',
    filterOperatorStartsWith: 'Empieza con',
    filterOperatorEndsWith: 'Termina con',
    filterOperatorIs: 'Es',
    filterOperatorNot: 'No es',
    filterOperatorAfter: 'Despues de',
    filterOperatorOnOrAfter: 'O despues de',
    filterOperatorBefore: 'antes de',
    filterOperatorOnOrBefore: 'O antes de',
    filterOperatorIsEmpty: 'Esta vacio',
    filterOperatorIsNotEmpty: 'No esta vacio',
    filterOperatorIsAnyOf: 'Es cualquiera de',
}