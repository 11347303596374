import { Container, Grid,  TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { setAdicionarProducto, setAdicionarValorPedido, setProductoActive } from '../../actions/productos';
import Swal from 'sweetalert2';

export const ProductoDetail = () => {

  const {codigo} = useParams();

  const{productos} = useSelector(state => state.tienda)
  const{categoriaActive} = useSelector(state => state.tienda)

  const dispatch = useDispatch()

  const initialValues = {'CategoriaCodigo':"", 'Codigo':"", 'Nombre':"", 'PorcentajeDescuento':"",'Notas':"", 'Precio':0,'UrlImagen':""};
  const initialValuesPedido = {'CategoriaCodigo':"", 'Codigo':"", 'Nombre':"", 'PorcentajeDescuento':"",'Notas':"", 'Precio':0,'UrlImagen':"", 'Cantidad':0,'ValorTotal':0};

  const [producto, setProducto] = useState(initialValues);
  const [pedido, setPedido] = useState(initialValuesPedido);
  const [total, setTotal] = useState(0);


  useEffect(() => {
        
        console.log(productos.find(producto => producto.Codigo === codigo));
        setProducto(productos.find(producto => producto.Codigo === codigo));
        //setPedido(...pedido, {...producto, Cantidad:1, ValorTotal:1  * producto.Precio});
        //setProducto(...producto, {ValorTotal: producto.Precio * 1, Cantidad: 1});

        
       
        dispatch(setProductoActive(producto));
     
  }, [productos, codigo, dispatch,producto]);

  const handleAddCart = () => {

    if(pedido.Cantidad.lenght === 0){
      Swal.fire("Atención", "Debe ingresar una cantidad", "warning");
      return;  
    }

    if(pedido.Cantidad < 1){
      Swal.fire("Advertencia", "La cantidad debe ser mayor a 0", "warning");
      return;
    }

    //setPedido({...pedido, 'Nombre': 'Sin datos'});
    //setPedido(...producto, { Cantidad:1, ValorTotal:1  * producto.Precio});
    
    console.log(producto);

    dispatch(setAdicionarProducto( {...pedido, 'Nombre': producto.Nombre,
     'ValorTotal': pedido.Cantidad * producto.Precio, 'Precio': producto.Precio,
      'Codigo': producto.Codigo, 'CategoriaCodigo': producto.CategoriaCodigo, 
      'UrlImagen': producto.UrlImagen}));
    dispatch(setAdicionarValorPedido(pedido.Cantidad * producto.Precio));

    // setPedido({...producto, ...pedido, 'Nombre': producto.Nombre, 'Cantidad': pedido.Cantidad,
    // 'ValorTotal': pedido.Cantidad * producto.Precio, 'Precio': producto.Precio,
    //  'Codigo': producto.Codigo, 'CategoriaCodigo': producto.CategoriaCodigo, 
    //  'UrlImagen': producto.UrlImagen});

     console.log("Producto Actual: ", producto);

    

     console.log("Pedido Actual: ", pedido);

    //Adicionar al local storage
    localStorage.getItem('pedido') 
    ? localStorage.setItem('pedido', JSON.stringify(JSON.parse(localStorage.getItem('pedido')).concat(
      pedido
    ))) 
    : localStorage.setItem('pedido', JSON.stringify([pedido]));

    //console.log(producto);
    Swal.fire("Agregado", "Se ha agregado tu producto al carrito", "success");
  }

  const handleChange = (event) => {
    setPedido({...pedido, [event.target.name]: event.target.value, 
      'Nombre':producto.Nombre, 'Precio': producto.Precio, 'Codigo': producto.Codigo});
      setTotal(event.target.value * producto.Precio);
    //console.log([event.target.name]);
    //console.log(event.target.value);
    console.log(pedido);
}

  return (
    <Container fixed>
       <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        {/* <Link 
              to="/categorias"
              className="btn-return"
          >
             Volver    
        </Link> */}
        <Link to={`/productos/${categoriaActive}`} className="btn-return">Volver a Productos</Link>
        <p style={{paddingTop:"20px",color:"White"}}>_____</p>
        <hr className="space-top"  />
        <h1 className="title animate__animated animate__fadeIn">{producto.Nombre}</h1>
        {/* <p className="subtitle">Conoce el detalle del producto</p> */}
        <hr className="space"/>

        <img src={producto.UrlImagen} alt={producto.Nombre} height="360px" width="360px" className="img-producto animate__animated animate__fadeInUp" />
        {/* <br />
        <p><strong>{producto.Nombre}</strong></p> */}
        <br />
        <p>{producto.Notas}</p>
        {/* <p>{producto.Precio}</p> */}
        <br />
        <strong>
          Precio: 
          <NumberFormat value={producto.Precio} displayType={'text'} thousandSeparator={true} prefix={' $ '} />
        </strong>
        <br />
        <br />
        {/* <Input
          type="number"
          name="cantidad"
          placeholder='Cantidad'
          /> */}
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <TextField label="Cantidad" name="Cantidad" 
            value={producto.Cantidad} onChange={handleChange} variant='outlined' type='number' fullWidth >

            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <strong>
              Total: 
              <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={' $ '} />
            </strong>
          </Grid>
          
        <br />
        
        <br />
        <button
          onClick={handleAddCart}
          className="btn btn-primary"
          >
            Agregar al carrito
          </button>
          <br />
          <br />
        
          <Link to={`/carrito`}>Ver carrito</Link>
    </Container>
  )
}
