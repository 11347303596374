import React, { useEffect, useState } from 'react'
import { Container, Grid } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import logo from './../../martillo_logo.jpg';

export const MarketplaceProductos = () => {

    const {categoriaId} = useParams();
    const [categoria, setCategoria] = useState("");
    

    useEffect(() => {
        if(categoriaId === "mobiliarios"){
            setCategoria("Mobiliarios");
        }
        else if(categoriaId === "mobiliariosSaldos"){
            setCategoria("Mobiliarios Saldos");
        }
        else if(categoriaId === "herramientaNueva"){
            setCategoria("Herramienta Nueva");
        }
        else if(categoriaId === "herramientaUsada"){
            setCategoria("Herramienta Usada");
        }
        else if(categoriaId === "serviciosMantenimiento")
        {
            setCategoria("Servicios de Mantenimiento");
        }
        else if(categoriaId === "serviciosManoObra")
        {
            setCategoria("Servicios Mano de Obra");
        }
        else if(categoriaId === "tapiceria")
        {
            setCategoria("Tapiceria");
        }
        else if(categoriaId === "estructurasMetalicas")
        {
            setCategoria("Estructuras Metalicas");
        }
        else if(categoriaId === "pintoresMadera")
        {
            setCategoria("Pintores de Madera");
        }
        else{
            setCategoria("Sin información de Categoria")
        }

    } , [categoriaId]);

  return (
    <div>
        <Container fixed>
            <p style={{paddingTop:"20px",color:"White"}}>_____</p>
            <Link 
                to="/marketplace"
                className="btn-return"
            >
                Volver a Marketplace
            </Link>
            <p style={{paddingTop:"20px",color:"White"}}>_____</p>
            <hr className="space-top" />
            
            <h1 className="title">{categoria}</h1>
            <p className="subtitle">Conoce todos los elementos relacionados con esta categoria</p>
            <hr className="space"/>

            <div className="card animate__animated animate__fadeIn">
            <Grid container spacing={0}>
                <Grid item xs={3} md={2} xl={1}>
                    <img src={logo} alt="Logo" height={60} width={60} ></img>
                </Grid>
                <Grid item xs={9} md={10} xl={11}>
                    <p className='card__paragraph'>
                        Actualmente no tenemos datos cargados en esta categoria,
                        pronto encontraras más información
                     </p>
                </Grid>
            </Grid>            
        </div>
        </Container>
    </div>
  )
}
